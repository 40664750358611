import styled from 'styled-components';

export const DisclaimerStyled = styled.p`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;

export const LinkStyled = styled.a`
  text-decoration: underline;
`;
