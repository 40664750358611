import { memo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { config } from 'core/config';
import {
  CardPaymentEntities,
  PaymentError,
  PaymentFormMerchantData,
  PaymentOrderDescription,
} from 'core/payments/entities';
import { PaymentForm, PaymentSubmit } from './types';
import { InvisibleGooglePay, Wrapper } from './styled';

export type CardPaymentProps = {
  formMerchantData: PaymentFormMerchantData | null;
  className?: string;
  params: Record<string, unknown>;
  onError?: (data: PaymentError) => void;
  onFailed?: (data: PaymentError) => void;
  onSubmitted?: (type: CardPaymentEntities) => void;
  onSucceed?: (data: { order?: PaymentOrderDescription }) => void;
  onVerify?: () => void;
  children?: ReactNode;
};

const PaymentCard = (props: CardPaymentProps) => {
  const {
    className,
    formMerchantData,
    onFailed,
    onSubmitted,
    onSucceed,
    onVerify,
    onError,
    params,
    children,
  } = props;

  const form = useRef<PaymentForm | null>(null);

  const containerId = 'solid-payment-form-container';

  const addFormListeners = useCallback(() => {
    const { current: paymentForm } = form;

    if (!paymentForm) return;

    paymentForm.on('error', (data: { data?: PaymentError } = {}) => {
      const errorData = data.data ? data.data : { message: 'Unknown error' };
      if (onError) {
        onError(errorData);
      }
    });
    paymentForm.on('fail', (data: { data?: PaymentError } = {}) => {
      const errorData = data.data ? data.data : { message: 'Unknown error' };
      if (onFailed) {
        onFailed(errorData);
      }
    });
    paymentForm.on('submit', (e: { data: PaymentSubmit }) => {
      if (onSubmitted) {
        onSubmitted(e.data.entity);
      }
    });
    paymentForm.on('success', (e: { data?: { order?: PaymentOrderDescription } }) => {
      const data = e.data || {};

      if (onSucceed) {
        onSucceed(data);
      }
    });
    paymentForm.on('verify', () => {
      if (onVerify) {
        onVerify();
      }
    });
  }, [onError, onFailed, onSubmitted, onSucceed, onVerify, form]);

  useEffect(() => {
    if (!form.current && formMerchantData && window.PaymentFormSdk) {
      form.current = window.PaymentFormSdk.init({
        merchantData: formMerchantData,
        iframeParams: {
          containerId,
        },
        applePayButtonParams: {
          containerId: config.applePayContainerId,
          integrationType: 'js',
        },
        googlePayButtonParams: {
          containerId: config.googlePayContainerId,
          type: 'plain',
        },
        ...params,
      });
    }

    addFormListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFormListeners, formMerchantData, form]);

  return (
    <>
      <Wrapper className={className} id={containerId}>
        {children}
      </Wrapper>
      <InvisibleGooglePay id={config.googlePayContainerId} />
    </>
  );
};

export default memo(PaymentCard);
