import { CSSProperties, ReactNode } from 'react';
import { ModalProps as ModalPropsMui } from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import NFade from './ModalFade';
import { StyledModal } from './styled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export type ModalProps = {
  open: boolean;
  className?: string;
  BackdropProps?: {
    className?: string;
    timeout?: number;
    style: CSSProperties;
  };
  children: ReactNode;
} & ModalPropsMui;

const Modal = (props: ModalProps) => {
  const { children, open, onClose, BackdropProps, className, ...otherProps } = props;

  const classes = useStyles();

  const mergedBackdropProps = {
    ...BackdropProps,
    style: {
      backgroundColor: 'rgba(60, 60, 60, 0.1)',
      backdropFilter: 'blur(10px)',
      ...BackdropProps?.style,
    },
  };

  const handleClose: ModalProps['onClose'] = (event, reason) => {
    if (onClose) onClose(event, reason);
  };

  return (
    <StyledModal
      {...otherProps}
      open={open}
      onClose={handleClose}
      BackdropProps={mergedBackdropProps}
      className={classes.modal}
    >
      <NFade className={className} in={open}>
        {children}
      </NFade>
    </StyledModal>
  );
};

export default Modal;
