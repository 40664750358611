import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';

type TabProps = MuiTabProps;

const Tab = ({ children, ...otherProps }: TabProps) => {
  return (
    <MuiTab disableRipple {...otherProps}>
      {children}
    </MuiTab>
  );
};

export default Tab;
