import { useEffect, useState } from 'react';
import { LoadingStatuses } from 'core/common/entities';
import { UnknownError } from 'core/common/errors';
import { useLogger, useServices } from 'core/common/hooks';
import { ProductDTO } from 'core/payments/services/dto';

export function useProductDescription(productName: string) {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.PENDING);
  const [error, setError] = useState('');
  const [product, setProduct] = useState<ProductDTO | null>(null);

  const { purchaseService } = useServices();
  const logger = useLogger();

  useEffect(() => {
    const fetchProduct = async () => {
      if (!productName) return;

      try {
        setLoadingStatus(LoadingStatuses.PENDING);
        const res = await purchaseService.getProduct(productName);
        setProduct(res);
        setLoadingStatus(LoadingStatuses.FULFILLED);
      } catch (err) {
        setLoadingStatus(LoadingStatuses.FAILED);
        if (err instanceof Error) {
          logger.error(err);
          setError(err.message);
        } else {
          logger.error(new UnknownError(err));
          setError('Sorry, something went wrong');
        }
      }
    };

    fetchProduct();
  }, [purchaseService, logger, productName]);

  return {
    loadingStatus,
    error,
    product,
  };
}
