import styled from 'styled-components';
import Address from 'core/common/components/Address';
import Image from 'core/common/components/Image';
import Icon from 'core/svg-sprites/ui/Icon';

export const Footer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const Text = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const Guarantee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const GuaranteeIconStyled = styled(Icon)`
  fill: ${({ theme }) => theme.colors.success500};
  margin-right: 5px;
`;

export const StyledAddress = styled(Address)`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const AddressUS = styled.p`
  max-width: 330px;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;

export const BrandsImageStyled = styled(Image)`
  display: block;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
`;
