import { FeatureRawResult, FeatureValue } from '../entities';
import { useFeatureFlags } from './useFeatureFlags';

export function useFeatureRawResult<T extends FeatureValue>(
  featureName: string,
): FeatureRawResult<T | null> {
  const featureFlags = useFeatureFlags();

  return featureFlags.getFeatureRawResult<T>(featureName);
}
