import { useCallback } from 'react';
import { useDispatch, useSelector } from 'core/common/hooks';
import { resignFormFailed, resignFormSucceed } from '../store/actions';
import { getPaymentProcessStep, getResignFormData } from '../store/selectors';

export function useResignPayment() {
  const dispatch = useDispatch();

  const resignFormData = useSelector(getResignFormData);
  const paymentProcessStep = useSelector(getPaymentProcessStep);

  const doResignFormSucceed = useCallback(() => {
    dispatch(resignFormSucceed());
  }, [dispatch]);

  const doResignFormFailed = useCallback(() => {
    dispatch(resignFormFailed());
  }, [dispatch]);

  return {
    resignFormData,
    paymentProcessStep,
    resignFormSucceed: doResignFormSucceed,
    resignFormFailed: doResignFormFailed,
  };
}
