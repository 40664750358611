import { useEffect, useState } from 'react';
import { useDispatch, useServices, useUrlLocation } from 'core/common/hooks';
import { getQueryParams } from 'core/common/utils/getQueryParams';
import { useUser, useUtmTags } from 'core/user/hooks';
import { updateUserInformation } from 'core/user/store';
import { OrderDTO } from '../services/dto';
import { updateOrderId } from '../store/actions';

// TODO move to thunk
export function useGetOrder() {
  const [order, setOrder] = useState<OrderDTO>();

  const dispatch = useDispatch();
  const { location } = useUrlLocation();
  const { purchaseService } = useServices();
  const { syncUtmTags } = useUtmTags();
  const { updateUserPreferences } = useUser();

  const searchParams = new URLSearchParams(location.query as unknown as string);

  const { order_id } = getQueryParams<{ order_id: string }>();

  useEffect(() => {
    if (!order_id) {
      return;
    }

    async function fetchOrderInfo() {
      const res = await purchaseService.getOrder(order_id, searchParams.toString());

      if (res) {
        updateUserPreferences(res.order_quiz_data);
        dispatch(updateUserInformation('orderData', { subjects: res.order_data.subjects }));
      }

      dispatch(updateOrderId(order_id));
      syncUtmTags({ user_id: res.user_id, email: res.order_quiz_data.email });

      setOrder(res);
    }

    fetchOrderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    order,
  };
}
