export enum ReportType {
  COMPATIBILITY = 'compatibility',
  NATAL_CHART = 'natal_chart',
  PALMISTRY = 'palmistry',
  HUMAN_DEIGN = 'human_design',
  WITCH = 'witch_reading',
  SHAMANS = 'shamans_reading',
  NUMEROLOGY = 'numerology_reading',
  TAROT = 'tarot_reading',
  PALMISTRY_GUIDE = 'palmistry_guide',
  ANGELOLOGY = 'angelology_reading',
  WEALTH = 'wealth_reading',
  SPIRIT_ANIMAL = 'spirit_animal',
  FEMININE_ARCHETYPE = 'feminine_archetype',
  ATTACHMENT_STYLE_MONTH_1 = 'attachment_style_month_1',
  ATTACHMENT_STYLE_MONTH_2 = 'attachment_style_month_2',
  ATTACHMENT_STYLE_MONTH_3 = 'attachment_style_month_3',
  MOON_PHASE_SOULMATES = 'moon_phase_soulmates',
}
