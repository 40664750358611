import styled from 'styled-components';
import ButtonClose, { CrossIconStyled } from 'core/common/components/ButtonClose';

export const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 95vw;
  max-height: 95vh;
  padding: 20px 15px 30px;
  border-radius: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.textPrimary};
  background: white;
`;

export const CloseButtonStyled = styled(ButtonClose)`
  width: fit-content;

  ${CrossIconStyled} {
    fill: ${({ theme }) => theme.colors.textPrimary};
  }
`;
