import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'core/common/hooks';
import { FunnelConfig } from 'core/funnel/entities';
import { getFunnelConfig, loadFunnelConfig, updateConfig } from '../store';

export const useFunnelConfig = (funnelConfig: FunnelConfig | null, funnelId: string) => {
  const dispatch = useDispatch();
  const { config: storageConfig, loadingStatus } = useSelector(getFunnelConfig);

  useEffect(() => {
    // Update Redux store with config from "getStaticProps"
    if (funnelConfig) {
      dispatch(updateConfig(funnelConfig));
    }

    // Load config (for direct navigation by URL)
    if (!funnelConfig && !storageConfig) {
      dispatch(loadFunnelConfig(funnelId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    config: funnelConfig ?? storageConfig,
    loadingStatus,
  };
};
