import { useCallback } from 'react';
import { useDispatch } from 'core/common/hooks';
import { createAskNebulaAccountRequest } from '../store';

export function useCreateAskNebulaAccount() {
  const dispatch = useDispatch();

  const createAccount = useCallback(() => {
    dispatch(createAskNebulaAccountRequest());
  }, [dispatch]);

  return {
    createAccount,
  };
}
