import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'core/common/hooks';
import { getAuthLoadingStatus, signInByToken } from '../store';
import { useGetTokenFromQuery } from './useGetTokenFromQuery';

export function useSignInByTokenFromQuery() {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getAuthLoadingStatus);

  const { token } = useGetTokenFromQuery();

  useEffect(() => {
    if (!token) return;

    dispatch(signInByToken(token));
  }, [token, dispatch]);

  return {
    loadingStatus,
  };
}
