import styled from 'styled-components';
import Image from '../Image';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  min-height: 320px;
  padding-top: 10px;
`;

export const TitleStyled = styled.h3`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

export const ImageStyled = styled(Image)`
  margin-top: auto;
  margin-bottom: auto;
`;

export const TextStyled = styled.p`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => props.theme.colors.typography500};
`;
