import { useSelector } from 'react-redux';
import { getSystemLanguage } from 'core/common/store/system';

export function useLanguage() {
  const language = useSelector(getSystemLanguage);

  return {
    language,
  };
}
