import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: -10px;

  > * {
    width: 100%;
  }
`;

export const ApplePayWrapper = styled.div`
  position: static;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  row-gap: 0.5em;
  width: 330px;
  height: 44px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 0;
  border-radius: 23px;
  box-sizing: border-box;
  column-gap: 0.5em;
  opacity: 1;
`;

export const InvisibleGooglePay = styled.div`
  display: none;
`;
