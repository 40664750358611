import styled from 'styled-components';
import { Tab } from 'core/common/components/Tabs';
import PaymentPaypal from '../PaymentPaypal';

export const PaymentPaypalStyled = styled(PaymentPaypal)`
  margin-bottom: 10px;
`;

export const TabStyled = styled(Tab)<{ isActive: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  height: 68px;
  border: 2px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.primary600 : theme.colors.primary100)};
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.typography100};

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const DividerStyled = styled.div`
  position: relative;
  margin-top: 28px;
  margin-bottom: 38px;
  text-align: center;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    width: 80px;
    height: 1px;
    background: ${({ theme }) => theme.colors.typography900};
    opacity: 0.1;
    content: '';
  }

  &::before {
    right: 20%;
  }

  &::after {
    left: 20%;
  }
`;
