import { useTranslation } from 'react-i18next';
import paymentBrands from 'core/common/assets/images/paymentForm/secure-brands.png';
import { config } from 'core/config';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import {
  AddressUS,
  BrandsImageStyled,
  Footer,
  Guarantee,
  GuaranteeIconStyled,
  StyledAddress,
  Text,
  Wrapper,
} from './styled';

const PaymentDescription = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'app-subscription-4-7d-trial-per-day.payment',
  });
  return (
    <Footer>
      <Wrapper>
        <Guarantee>
          <GuaranteeIconStyled name="guarantee" width={24} height={24} />
          <Text>{t('resources')}</Text>
        </Guarantee>
      </Wrapper>
      <BrandsImageStyled src={paymentBrands} alt="Payment brands" />
      <Location>
        <LocationOption option={Locales.US}>
          <AddressUS>{config.addressUS}</AddressUS>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <StyledAddress />
        </LocationOption>
      </Location>
    </Footer>
  );
};

export default PaymentDescription;
