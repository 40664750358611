import { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import applePay from 'core/common/assets/images/paymentForm/applepay.png';
import card from 'core/common/assets/images/paymentForm/card.svg';
import paypal from 'core/common/assets/images/paymentForm/paypal.png';
import CardPaymentDisclaimer from 'core/common/components/CardPaymentDisclaimer';
import Image from 'core/common/components/Image';
import { TabPanel, Tabs } from 'core/common/components/Tabs';
import { useConfig } from 'core/common/hooks';
import {
  CardPaymentEntities,
  PaymentError,
  PaymentFormMerchantData,
  PaymentFormPaypalData,
  PaymentOrderDescription,
  PaymentType,
} from 'core/payments/entities';
import CardPayment, { ApplePayWrapper, defaultParams } from '../PaymentCard';
import PaymentDescription from '../PaymentDescription';
import { PaymentPaypalStyled, TabStyled } from './styled';

export type PaymentMethodsProps = {
  onPaymentClick: (payment: PaymentType) => void;
  children?: ReactNode;
  shouldRenderPaypal: boolean;
  shouldRenderCardPayment: boolean;
  shouldRenderApplePay: boolean;
  formMerchantData: PaymentFormMerchantData | null;
  onFormFailed: (data: PaymentError) => void;
  onCardFormError: (data: unknown) => void;
  onVerify: () => void;
  onOrderPaypalStartProcessing: () => void;
  onOrderCardStartProcessing: (paymentType: CardPaymentEntities) => void;
  onSucceed: (data: { order?: PaymentOrderDescription }) => void;
  paypalPaymentUrl: PaymentFormPaypalData | null;
  onPaypalFailed: (data: PaymentError) => void;
  defaultPaymentMethod?: PaymentType;
};

const PaymentMethods = ({
  onPaymentClick,
  children,
  shouldRenderPaypal,
  shouldRenderCardPayment,
  shouldRenderApplePay,
  formMerchantData,
  onFormFailed,
  onCardFormError,
  onVerify,
  onOrderPaypalStartProcessing,
  onOrderCardStartProcessing,
  onSucceed,
  paypalPaymentUrl,
  onPaypalFailed,
  defaultPaymentMethod = PaymentType.PAYPAL,
}: PaymentMethodsProps) => {
  const config = useConfig();

  const [activeTab, setActiveTab] = useState(defaultPaymentMethod);

  const handleTabChange = (_: SyntheticEvent, newValue: PaymentType) => {
    setActiveTab(newValue);
    onPaymentClick(newValue);
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'app-subscription-4-7d-trial-per-day.payment',
  });

  return (
    <>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <TabStyled
          isActive={activeTab === PaymentType.PAYPAL}
          value={PaymentType.PAYPAL}
          icon={<Image src={paypal} width={52} height={16} alt="paypal" />}
        />
        {shouldRenderApplePay && (
          <TabStyled
            isActive={activeTab === PaymentType.APPLE_PAY}
            value={PaymentType.APPLE_PAY}
            icon={<Image src={applePay} width={38} height={16} alt="apple-pay" />}
          />
        )}
        <TabStyled
          isActive={activeTab === PaymentType.CARD}
          value={PaymentType.CARD}
          icon={<Image src={card} alt="card" width={71} height={24} />}
        />
      </Tabs>
      {children}
      <TabPanel isActive={activeTab === PaymentType.PAYPAL}>
        {shouldRenderPaypal && (
          <PaymentPaypalStyled
            url={paypalPaymentUrl}
            onFailed={onPaypalFailed}
            onOrderStartProcessing={onOrderPaypalStartProcessing}
            onSucceed={onSucceed}
          />
        )}
      </TabPanel>
      {shouldRenderApplePay && (
        <TabPanel isActive={activeTab === PaymentType.APPLE_PAY}>
          <ApplePayWrapper
            onClick={() => onPaymentClick(PaymentType.APPLE_PAY)}
            id={config.applePayContainerId}
          />
        </TabPanel>
      )}
      <TabPanel isActive={activeTab === PaymentType.CARD}>
        {shouldRenderCardPayment && (
          <>
            <CardPayment
              formMerchantData={formMerchantData}
              onFailed={onFormFailed}
              onError={onCardFormError}
              onVerify={onVerify}
              onSubmitted={onOrderCardStartProcessing}
              onSucceed={onSucceed}
              params={defaultParams(t)}
            />
            <CardPaymentDisclaimer />
          </>
        )}
      </TabPanel>
      <PaymentDescription />
    </>
  );
};

export default PaymentMethods;
