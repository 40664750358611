import { TabPanelStyled } from './styled';

interface TabPanelProps {
  children: React.ReactNode;
  isActive: boolean;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, className = '', isActive, ...otherProps } = props;

  return (
    <TabPanelStyled isActive={isActive} role="tabpanel" {...otherProps} className={className}>
      {children}
    </TabPanelStyled>
  );
};

export default TabPanel;
