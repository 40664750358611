import { ReactNode } from 'react';
import { Languages } from 'core/localization/entities';
import { useLanguage } from 'core/localization/hooks';

export type IfLanguageProps = {
  children?: ReactNode;
  value: Languages;
};

const IfLanguage = ({ value, children }: IfLanguageProps) => {
  const { language } = useLanguage();

  if (language !== value) return null;

  return <>{children}</>;
};

export default IfLanguage;
