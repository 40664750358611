import styled from 'styled-components';

export const SolutionWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
  padding-left: 12px;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background: ${(props) => props.theme.gradients.secondaryLight};
    content: '';
  }
`;

export const SolutionText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  color: ${(props) => props.theme.colors.typography600};
`;
