import { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import applePay from 'core/common/assets/images/paymentForm/applepay.png';
import card from 'core/common/assets/images/paymentForm/card.svg';
import CardPaymentDisclaimer from 'core/common/components/CardPaymentDisclaimer';
import Image from 'core/common/components/Image';
import { TabPanel, Tabs } from 'core/common/components/Tabs';
import { useConfig } from 'core/common/hooks';
import {
  CardPaymentEntities,
  PaymentError,
  PaymentFormMerchantData,
  PaymentOrderDescription,
  PaymentType,
} from 'core/payments/entities';
import CardPayment, { ApplePayWrapper, defaultParams } from '../PaymentCard';
import PaymentDescription from '../PaymentDescription';
import { TabStyled } from './styled';

type PaymentMethodsProps = {
  onPaymentClick: (payment: PaymentType) => void;
  children?: ReactNode;
  shouldRenderCardPayment: boolean;
  shouldRenderApplePay: boolean;
  formMerchantData: PaymentFormMerchantData | null;
  onFormFailed: (data: PaymentError) => void;
  onCardFormError: (data: unknown) => void;
  onVerify: () => void;
  onOrderCardStartProcessing: (paymentType: CardPaymentEntities) => void;
  onSucceed: (data: { order?: PaymentOrderDescription }) => void;
  initialPaymentMethod?: PaymentType;
};

const PaymentMethods = ({
  onPaymentClick,
  children,
  shouldRenderCardPayment,
  shouldRenderApplePay,
  formMerchantData,
  onFormFailed,
  onCardFormError,
  onVerify,
  onOrderCardStartProcessing,
  onSucceed,
  initialPaymentMethod = PaymentType.CARD,
}: PaymentMethodsProps) => {
  const config = useConfig();

  const [activeTab, setActiveTab] = useState(initialPaymentMethod);

  const handleTabChange = (_: SyntheticEvent, newValue: PaymentType) => {
    setActiveTab(newValue);
    onPaymentClick(newValue);
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'app-subscription-4-7d-trial-per-day.payment',
  });

  return (
    <>
      <Tabs value={activeTab} onChange={handleTabChange}>
        {shouldRenderApplePay && (
          <TabStyled
            isActive={activeTab === PaymentType.APPLE_PAY}
            value={PaymentType.APPLE_PAY}
            icon={<Image src={applePay} width={38} height={16} alt="apple-pay" />}
          />
        )}
        <TabStyled
          isActive={activeTab === PaymentType.CARD}
          value={PaymentType.CARD}
          icon={<Image src={card} alt="card" width={71} height={24} />}
        />
      </Tabs>
      {children}
      <TabPanel isActive={activeTab === PaymentType.APPLE_PAY}>
        {shouldRenderApplePay && (
          <ApplePayWrapper
            onClick={() => onPaymentClick(PaymentType.APPLE_PAY)}
            id={config.applePayContainerId}
          />
        )}
      </TabPanel>
      <TabPanel isActive={activeTab === PaymentType.CARD}>
        {shouldRenderCardPayment && (
          <CardPayment
            formMerchantData={formMerchantData}
            onFailed={onFormFailed}
            onError={onCardFormError}
            onVerify={onVerify}
            onSubmitted={onOrderCardStartProcessing}
            onSucceed={onSucceed}
            params={defaultParams(t)}
          />
        )}
      </TabPanel>
      <CardPaymentDisclaimer />
      <PaymentDescription />
    </>
  );
};

export default PaymentMethods;
