import { ReactNode } from 'react';
import { SolutionText, SolutionWrapper } from './styled';

export type PaymentErrorSolutionProps = {
  className?: string;
  children?: ReactNode;
};

const PaymentErrorSolution = ({ children, className }: PaymentErrorSolutionProps) => {
  return (
    <SolutionWrapper className={className}>
      <SolutionText>{children}</SolutionText>
    </SolutionWrapper>
  );
};

export default PaymentErrorSolution;
