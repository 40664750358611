import { t } from 'i18next';
import { useSelector } from 'react-redux';
import {
  Currency,
  CurrencySymbol,
  europeanCountries,
  firstTierCountries,
  Locales,
} from 'core/user/entities';
import { useUserLocation } from 'core/user/hooks';
import { getPlan } from 'core/user/store';
import { PurchaseProductName } from '../entities';

const trialCreditsUpsellsOtherWorldPlan = {
  oldPrice: '$99.99',
  discount: '50%',
  value: PurchaseProductName.INTRO_APPNEBULA_UPSELL_ONE_TIME_USD_49_99_900_CREDITS,
};

const trialCreditsUpsellsEuropeanPlan = {
  oldPrice: '€99.99',
  discount: '50%',
  value: PurchaseProductName.INTRO_APPNEBULA_UPSELL_ONE_TIME_EUR_49_99_900_CREDITS,
};

const premiumUpsellOtherWorldMonthlyPlan = {
  oldPrice: '$14.59',
  discount: '',
  value: PurchaseProductName.PREMIUM_UPSELL_14_59_USD_1M,
};

const premiumUpsellEuropeanMonthlyPlan = {
  oldPrice: '€14.59',
  discount: '',
  value: PurchaseProductName.PREMIUM_UPSELL_14_59_EUR_1M,
};

const trialUpsellOtherWorldPlan = {
  oldPrice: '$19',
  price: '$17',
  discount: '10%',
  value: PurchaseProductName.APP_SUBSCRIPTION_APPNEBULA_PREMIUM_2W_17,
};

const trialUpsellEuropeanPlan = {
  oldPrice: '€19',
  price: '€17',
  discount: '10%',
  value: PurchaseProductName.APP_SUBSCRIPTION_APPNEBULA_PREMIUM_2W_17_EUR,
};

const upsellsOtherWorldSpanishPlans = {
  threeDays: {
    text: '$3.99',
    description: t(`plans.short`),
    value: PurchaseProductName.APP_SUBSCRIPTION_3_99_USD_UPSELL_3PCS_7D,
  },
  tenDays: {
    text: '$2.99',
    description: t(`plans.long`),
    value: PurchaseProductName.APP_SUBSCRIPTION_2_99_USD_UPSELL_10PCS_7D,
  },
};

const upsellsEuropeanSpanishPlans = {
  threeDays: {
    text: '€3.99',
    description: t(`plans.short`),
    value: PurchaseProductName.APP_SUBSCRIPTION_3_99_EUR_UPSELL_3PCS_7D,
  },
  tenDays: {
    text: '€2.99',
    description: t(`plans.long`),
    value: PurchaseProductName.APP_SUBSCRIPTION_2_99_EUR_UPSELL_10PCS_7D,
  },
};

const upsellsOtherWorlsPlans = {
  numerologyAnalysis: {
    price: '$14.99',
    oldPriceDescription: t(`plans.numerologyAnalysis.oldPriceDescription`),
    oldPrice: '$29.99',
    discount: t(`plans.numerologyAnalysis.discount`),
    title: t(`plans.numerologyAnalysis.title`),
    emoji: 'input_numbers',
    value: PurchaseProductName.NUMEROLOGY_REPORT_14_99,
  },
  tarotReading: {
    price: '$19.99',
    oldPriceDescription: t(`plans.tarotReading.oldPriceDescription`),
    oldPrice: '$34.99',
    discount: t(`plans.tarotReading.discount`),
    title: t(`plans.tarotReading.title`),
    emoji: 'sunset',
    value: PurchaseProductName.TAROT_REPORT_19_99,
  },
  palmistryGuide: {
    price: '$19.99',
    oldPriceDescription: t(`plans.palmistryGuide.oldPriceDescription`),
    oldPrice: '$29.99',
    discount: t(`plans.palmistryGuide.discount`),
    title: t(`plans.palmistryGuide.title`),
    emoji: 'rised_hand',
    value: PurchaseProductName.PALMISTRY_REPORT_19_99,
  },
  ultraPack: {
    price: '$49.99',
    oldPrice: '$99.99',
    oldPriceDescription: t(`plans.ultraPack.oldPriceDescription`),
    description: t(`plans.ultraPack.description`),
    discount: t(`plans.ultraPack.discount`),
    title: t(`plans.ultraPack.title`),
    emoji: 'star_struck',
    value: PurchaseProductName.ULTRA_PACK_5_REPORTS_49_99,
  },
};

const upsellsEuropeanPlans = {
  numerologyAnalysis: {
    price: '€14.99',
    oldPriceDescription: t(`plans.numerologyAnalysis.oldPriceDescription`),
    oldPrice: '€29.99',
    discount: t(`plans.numerologyAnalysis.discount`),
    title: t(`plans.numerologyAnalysis.title`),
    emoji: 'input_numbers',
    value: PurchaseProductName.NUMEROLOGY_REPORT_14_99_EUR,
  },
  tarotReading: {
    price: '€19.99',
    oldPriceDescription: t(`plans.tarotReading.oldPriceDescription`),
    oldPrice: '€34.99',
    discount: t(`plans.tarotReading.discount`),
    title: t(`plans.tarotReading.title`),
    emoji: 'sunset',
    value: PurchaseProductName.TAROT_REPORT_19_99_EUR,
  },
  palmistryGuide: {
    price: '€19.99',
    oldPriceDescription: t(`plans.palmistryGuide.oldPriceDescription`),
    oldPrice: '€29.99',
    discount: t(`plans.palmistryGuide.discount`),
    title: t(`plans.palmistryGuide.title`),
    emoji: 'rised_hand',
    value: PurchaseProductName.PALMISTRY_REPORT_19_99_EUR,
  },
  ultraPack: {
    price: '€49.99',
    oldPrice: '€99.99',
    oldPriceDescription: t(`plans.ultraPack.oldPriceDescription`),
    description: t(`plans.ultraPack.description`),
    discount: t(`plans.ultraPack.discount`),
    title: t(`plans.ultraPack.title`),
    emoji: 'star_struck',
    value: PurchaseProductName.ULTRA_PACK_5_REPORTS_49_99_EUR,
  },
};

const palmistryUpsellsOtherWorlsPlans = {
  tarotReading: {
    price: '$19.99',
    oldPriceDescription: 'was',
    oldPrice: '$34.99',
    discount: '45% OFF',
    title: 'Tarot reading',
    emoji: 'sunset',
    value: PurchaseProductName.TAROT_REPORT_19_99,
    order: 1,
  },
  numerologyAnalysis: {
    price: '$14.99',
    oldPriceDescription: 'was',
    oldPrice: '$29.99',
    discount: '50% OFF',
    title: 'Numerology analysis',
    emoji: 'input_numbers',
    value: PurchaseProductName.NUMEROLOGY_REPORT_14_99,
    order: 2,
  },
  ultraPack: {
    price: '$29.99',
    oldPrice: '$64.99',
    oldPriceDescription: 'regular price',
    description: '(2 in 1 + 2 secret bonus readings)',
    discount: '54% OFF',
    title: 'Ultra pack ',
    emoji: 'star_struck',
    value: PurchaseProductName.ULTRA_PACK_4_REPORTS_29_99,
    order: 3,
  },
};

const palmistryUpsellsOtherWorlsPlansUltraPackFirst = {
  tarotReading: {
    price: '$19.99',
    oldPriceDescription: 'was',
    oldPrice: '$34.99',
    discount: '45% OFF',
    title: 'Tarot reading',
    emoji: 'sunset',
    value: PurchaseProductName.TAROT_REPORT_19_99,
    order: 3,
  },
  numerologyAnalysis: {
    price: '$14.99',
    oldPriceDescription: 'was',
    oldPrice: '$29.99',
    discount: '50% OFF',
    title: 'Numerology analysis',
    emoji: 'input_numbers',
    value: PurchaseProductName.NUMEROLOGY_REPORT_14_99,
    order: 2,
  },
  ultraPack: {
    price: '$29.99',
    oldPrice: '$64.99',
    oldPriceDescription: 'regular price',
    description: '(2 in 1 + 2 secret bonus readings)',
    discount: '54% OFF',
    title: 'Ultra pack ',
    emoji: 'star_struck',
    value: PurchaseProductName.ULTRA_PACK_4_REPORTS_29_99,
    order: 1,
  },
};

const palmistryUpsellsEuropeanPlans = {
  numerologyAnalysis: {
    price: '€14.99',
    oldPriceDescription: t(`plans.numerologyAnalysis.oldPriceDescription`),
    oldPrice: '€29.99',
    discount: '50% OFF',
    title: 'Numerology analysis',
    emoji: 'input_numbers',
    value: PurchaseProductName.NUMEROLOGY_REPORT_14_99_EUR,
    order: 2,
  },
  tarotReading: {
    price: '€19.99',
    oldPriceDescription: 'was',
    oldPrice: '€34.99',
    discount: '45% OFF',
    title: 'Tarot reading',
    emoji: 'sunset',
    value: PurchaseProductName.TAROT_REPORT_19_99_EUR,
    order: 1,
  },
  ultraPack: {
    price: '€29.99',
    oldPrice: '€64.99',
    oldPriceDescription: 'regular price',
    description: '(2 in 1 + 2 secret bonus readings)',
    discount: '54% OFF',
    title: 'Ultra pack ',
    emoji: 'star_struck',
    value: PurchaseProductName.ULTRA_PACK_4_REPORTS_29_99_EUR,
    order: 3,
  },
};

const palmistryUpsellsEuropeanPlansUltraPackFirst = {
  numerologyAnalysis: {
    price: '€14.99',
    oldPriceDescription: t(`plans.numerologyAnalysis.oldPriceDescription`),
    oldPrice: '€29.99',
    discount: '50% OFF',
    title: 'Numerology analysis',
    emoji: 'input_numbers',
    value: PurchaseProductName.NUMEROLOGY_REPORT_14_99_EUR,
    order: 2,
  },
  tarotReading: {
    price: '€19.99',
    oldPriceDescription: 'was',
    oldPrice: '€34.99',
    discount: '45% OFF',
    title: 'Tarot reading',
    emoji: 'sunset',
    value: PurchaseProductName.TAROT_REPORT_19_99_EUR,
    order: 3,
  },
  ultraPack: {
    price: '€29.99',
    oldPrice: '€64.99',
    oldPriceDescription: 'regular price',
    description: '(2 in 1 + 2 secret bonus readings)',
    discount: '54% OFF',
    title: 'Ultra pack ',
    emoji: 'star_struck',
    value: PurchaseProductName.ULTRA_PACK_4_REPORTS_29_99_EUR,
    order: 1,
  },
};

const plansUSBucket = [
  {
    displayPrice: '$1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_45,
    price: 45,
    oldPrice: 65,
  },
  {
    displayPrice: '$5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_45,
    price: 45,
    oldPrice: 65,
  },
  {
    displayPrice: '$9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_45,
    price: 45,
    oldPrice: 65,
  },
  {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_45,
    price: 45,
    isTip: true,
    oldPrice: 65,
  },
];

const plansUSBucketTimeUp = [
  {
    displayPrice: '$1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_65,
    price: 65,
    oldPrice: 65,
  },
  {
    displayPrice: '$5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_65,
    price: 65,
    oldPrice: 65,
  },
  {
    displayPrice: '$9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_65,
    price: 65,
    oldPrice: 65,
  },
  {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_65,
    price: 65,
    isTip: true,
    oldPrice: 65,
  },
];

const plansEuropeanBucket = [
  {
    displayPrice: '€1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_39,
    price: 39,
    oldPrice: 55,
  },
  {
    displayPrice: '€5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_39,
    price: 39,
    oldPrice: 55,
  },
  {
    displayPrice: '€9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_45,
    price: 45,
    oldPrice: 65,
  },
  {
    displayPrice: '€13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_45,
    price: 45,
    isTip: true,
    oldPrice: 65,
  },
];

const plansEuropeanBucketTimeUp = [
  {
    displayPrice: '€1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_55,
    price: 55,
    oldPrice: 55,
  },
  {
    displayPrice: '€5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_55,
    price: 55,
    oldPrice: 55,
  },
  {
    displayPrice: '€9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_65,
    price: 65,
    oldPrice: 65,
  },
  {
    displayPrice: '€13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_65,
    price: 65,
    isTip: true,
    oldPrice: 65,
  },
];

const plansFirstTierBucket = [
  {
    displayPrice: '$1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_39,
    price: 39,
    oldPrice: 55,
  },
  {
    displayPrice: '$5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_39,
    price: 39,
    oldPrice: 55,
  },
  {
    displayPrice: '$9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_45,
    price: 45,
    oldPrice: 65,
  },
  {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_45,
    price: 45,
    isTip: true,
    oldPrice: 65,
  },
];

const plansFirstTierBucketTimeUp = [
  {
    displayPrice: '$1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_55,
    price: 55,
    oldPrice: 55,
  },
  {
    displayPrice: '$5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_55,
    price: 55,
    oldPrice: 55,
  },
  {
    displayPrice: '$9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_65,
    price: 65,
    oldPrice: 65,
  },
  {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_65,
    price: 65,
    isTip: true,
    oldPrice: 65,
  },
];

const plansSecondTierBucket = [
  {
    displayPrice: '$1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_39,
    price: 39,
    oldPrice: 55,
  },
  {
    displayPrice: '$5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_39,
    price: 39,
    oldPrice: 55,
  },
  {
    displayPrice: '$9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_45,
    price: 45,
    oldPrice: 65,
  },
  {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_45,
    price: 45,
    isTip: true,
    oldPrice: 65,
  },
];

const plansSecondTierBucketTimeUp = [
  {
    displayPrice: '$1',
    productId: PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_55,
    price: 55,
    oldPrice: 55,
  },
  {
    displayPrice: '$5',
    productId: PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_55,
    price: 55,
    oldPrice: 55,
  },
  {
    displayPrice: '$9',
    productId: PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_65,
    price: 65,
    oldPrice: 65,
  },
  {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_65,
    price: 65,
    isTip: true,
    oldPrice: 65,
  },
];

export const useGetActualPaymentPlans = () => {
  const { isEuropean } = useUserLocation();
  const { locale } = useUserLocation();

  const selectedSubscription = useSelector(getPlan);

  const getPlans = () => {
    if (locale === Locales.US) {
      return plansUSBucket;
    }

    if (europeanCountries.includes(locale)) {
      return plansEuropeanBucket;
    }

    if (firstTierCountries.includes(locale)) {
      return plansFirstTierBucket;
    }

    return plansSecondTierBucket;
  };

  const getTimeUpPlans = () => {
    if (locale === Locales.US) {
      return plansUSBucketTimeUp;
    }

    if (europeanCountries.includes(locale)) {
      return plansEuropeanBucketTimeUp;
    }

    if (firstTierCountries.includes(locale)) {
      return plansFirstTierBucketTimeUp;
    }

    return plansSecondTierBucketTimeUp;
  };

  const upsellsSpanishPlans = isEuropean
    ? upsellsEuropeanSpanishPlans
    : upsellsOtherWorldSpanishPlans;

  const getPalmistryUpsellsPlans = () => {
    const displayPriceWithoutCurrencySymbol = selectedSubscription.displayPrice
      ? selectedSubscription.displayPrice.slice(1)
      : 0;

    const isCheapestPlanSelected = Number(displayPriceWithoutCurrencySymbol) === 1;

    if (isEuropean && isCheapestPlanSelected) {
      return palmistryUpsellsEuropeanPlans;
    }

    if (!isEuropean && isCheapestPlanSelected) {
      return palmistryUpsellsOtherWorlsPlans;
    }

    if (isEuropean && !isCheapestPlanSelected) {
      return palmistryUpsellsEuropeanPlansUltraPackFirst;
    }

    return palmistryUpsellsOtherWorlsPlansUltraPackFirst;
  };

  const getUpsellsPlans = () => {
    if (isEuropean) {
      return upsellsEuropeanPlans;
    }
    return upsellsOtherWorlsPlans;
  };

  const getTrialCreditsUpsellPlan = () => {
    if (isEuropean) {
      return trialCreditsUpsellsEuropeanPlan;
    }
    return trialCreditsUpsellsOtherWorldPlan;
  };

  const getPremiumUpsellPlan = () => {
    // European + monthly experiment
    if (isEuropean) {
      return premiumUpsellEuropeanMonthlyPlan;
    }

    return premiumUpsellOtherWorldMonthlyPlan;
  };

  const upsellsPlans = getUpsellsPlans();

  const palmistryUpsellsPlan = getPalmistryUpsellsPlans();

  const trialCreditsUpsellPlan = getTrialCreditsUpsellPlan();

  const premiumUpsellPlan = getPremiumUpsellPlan();

  const trialUpsellPlan = isEuropean ? trialUpsellEuropeanPlan : trialUpsellOtherWorldPlan;

  const currencySymbol = isEuropean ? CurrencySymbol.EUR : CurrencySymbol.USD;

  const currency = isEuropean ? Currency.EUR : Currency.USD;

  return {
    currencySymbol,
    trialUpsellPlan,
    upsellsSpanishPlans,
    palmistryUpsellsPlan,
    trialCreditsUpsellPlan,
    upsellsPlans,
    premiumUpsellPlan,
    currency,
    plans: getPlans(),
    timeUpPlans: getTimeUpPlans(),
  };
};
