import MuiTabs from '@mui/material/Tabs';
import styled from 'styled-components';

const PREFIX = 'MuiTabs';

const classes = {
  indicator: `.${PREFIX}-indicator`,
};

export const TabsStyled = styled(MuiTabs)`
  & ${classes.indicator} {
    display: none;
  }
`;
