import { ReactElement } from 'react';
import Case, { CaseProps } from '../Case';

export type SwitchProps = {
  value?: unknown;
  children: ReactElement<CaseProps, typeof Case>[] | ReactElement<CaseProps, typeof Case>;
};

const Switch = ({ value, children }: SwitchProps) => {
  if (!Array.isArray(children)) {
    const defaultComponent = !!children.props.default;

    const foundCase = children.props.value === value;

    return foundCase || defaultComponent ? <>{children}</> : null;
  }

  const defaultComponent = children.find(({ props }) => !!props.default);

  const foundCase = children.filter(({ props }) => props.value === value);

  return foundCase.length ? <>{foundCase}</> : <>{defaultComponent}</>;
};

export default Switch;
