import { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { TabsStyled } from './styled';

type TabsProps = MuiTabsProps;

const Tabs = ({ children, ...otherProps }: TabsProps) => {
  return <TabsStyled {...otherProps}>{children}</TabsStyled>;
};

export default Tabs;
