import { ReactNode, useEffect } from 'react';
import { PaymentForm } from 'core/common/components/Payment';
import { useServices } from 'core/common/hooks';
import { PaymentOrderDescription } from 'core/payments/entities';
import { Currency } from 'core/user/entities';
import { CloseButtonStyled, MountedModalStyled, WrapperStyled } from './styled';

export type PaymentDialogProps = {
  isOpen?: boolean;
  productId: string;
  onClose?: () => void;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
  onPaymentFailed?: () => void;
  children?: ReactNode;
  currency: Currency;
};

const PaymentDialog = (props: PaymentDialogProps) => {
  const {
    isOpen = false,
    onClose,
    onPaymentFailed,
    onPaymentSuccess,
    productId,
    children,
    currency = Currency.USD,
  } = props;

  const { analyticsService } = useServices();

  useEffect(() => {
    if (isOpen) {
      analyticsService.trackEvent('payment_form_open');
    }
  }, [isOpen, analyticsService]);

  const close = () => {
    analyticsService.trackEvent('payment_form_close');

    if (onClose) {
      onClose();
    }
  };

  return (
    <MountedModalStyled open={isOpen} hidden={!isOpen} onClose={close}>
      <WrapperStyled>
        <CloseButtonStyled onClick={close} />
        <PaymentForm
          onPaymentFailed={onPaymentFailed}
          onPaymentSuccess={onPaymentSuccess}
          productId={productId}
          currency={currency}
        >
          {children}
        </PaymentForm>
      </WrapperStyled>
    </MountedModalStyled>
  );
};

export default PaymentDialog;
