import styled from 'styled-components';

export const EmojiContainer = styled.span<{
  url: string;
  desktopLarge: boolean;
  width: number;
  height: number;
}>`
  display: inline-block;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-image: url('${(props) => props.url}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
