import { ComponentProps } from 'react';
import { Resign as SolidResign } from '@solidgate/react-sdk';
import { PaymentError, PaymentErrorCode, PaymentOrderDescription } from 'core/payments/entities';

import { RootStyled } from './styled';

type SolidResignFormProps = ComponentProps<typeof SolidResign>;

export type PaymentResignFormProps = {
  className?: string;
  onFail?: (error: PaymentError) => void;
  onSuccess?: (data: { order: PaymentOrderDescription }) => void;
  onError?: (error: PaymentError) => void;
} & Omit<SolidResignFormProps, 'onFail' | 'onSuccess' | 'onError'>;

const PaymentResignForm = ({
  className,
  onFail,
  onSuccess,
  onError: onErrorPassed,
  ...otherProps
}: PaymentResignFormProps) => {
  const onError: SolidResignFormProps['onError'] = (error) => {
    const { details = {}, value } = error;
    const code = error.details && (error.details.code as PaymentErrorCode);

    if (!onErrorPassed) return;

    onErrorPassed({
      message: value.message,
      code,
      details: { ...details, name: value.name },
    });
  };

  return (
    <RootStyled className={className}>
      <SolidResign
        onSuccess={onSuccess as SolidResignFormProps['onSuccess']}
        onFail={onFail as SolidResignFormProps['onFail']}
        onError={onError}
        {...otherProps}
      />
    </RootStyled>
  );
};

export default PaymentResignForm;
