import styled from 'styled-components';
import ButtonClose from 'core/common/components/ButtonClose';

export const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 95vw;
  max-height: 95vh;
  padding: 35px 5px 10px;
  border-radius: 20px;
  overflow: hidden;
  background: white;
`;

export const CloseButtonStyled = styled(ButtonClose)`
  position: absolute;
  top: 20px;
  left: 20px;
`;
