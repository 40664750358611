import styled from 'styled-components';

export const RootStyled = styled.div`
  width: 100%;
  min-height: 175px;
  border-radius: 16px;

  iframe {
    width: 100% !important;
  }
`;
