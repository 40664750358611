import { useEffect } from 'react';
import { BingAdsScriptsService } from '../services';

export const useInitBingAds = (ids: Array<string>) => {
  useEffect(() => {
    const fragment = document.createDocumentFragment();

    ids.forEach((id) => {
      const script = document.createElement('script');
      script.textContent = BingAdsScriptsService.getScriptCode(id);
      script.id = `bing-ads-${id}`;

      const scriptExists = document.getElementById(`bing-ads-${id}`);

      if (scriptExists) {
        return;
      }

      fragment.appendChild(script);
    });

    document.body.appendChild(fragment);
    // Append script just once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
