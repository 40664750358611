/* eslint-disable max-len */
import hundred from 'core/common/assets/images/emoji/100.png';
import airplane from 'core/common/assets/images/emoji/airplane.png';
import alarm_clock from 'core/common/assets/images/emoji/alarm_clock.png';
import angry from 'core/common/assets/images/emoji/angry.png';
import anxious_face_with_sweat from 'core/common/assets/images/emoji/anxious_face_with_sweat.png';
import aquarius from 'core/common/assets/images/emoji/aquarius.png';
import artist from 'core/common/assets/images/emoji/artist.png';
import bar_chart from 'core/common/assets/images/emoji/bar_chart.png';
import bicyclist from 'core/common/assets/images/emoji/bicyclist.png';
import blue_book from 'core/common/assets/images/emoji/blue_book.png';
import blue_heart from 'core/common/assets/images/emoji/blue_heart.png';
import brain from 'core/common/assets/images/emoji/brain.png';
import briefcase from 'core/common/assets/images/emoji/briefcase.png';
import broken_heart from 'core/common/assets/images/emoji/broken_heart.png';
import bullseye from 'core/common/assets/images/emoji/bullseye.png';
import movie_camera from 'core/common/assets/images/emoji/camera.png';
import check_mark_button from 'core/common/assets/images/emoji/check_mark_button.png';
import cherry_blossom from 'core/common/assets/images/emoji/cherry_blossom.png';
import chocolate_bar from 'core/common/assets/images/emoji/chocolate_bar.png';
import cityscape_at_dusk from 'core/common/assets/images/emoji/cityscape_at_dusk.png';
import cloud from 'core/common/assets/images/emoji/cloud.png';
import compass from 'core/common/assets/images/emoji/compass.png';
import confused from 'core/common/assets/images/emoji/confused.png';
import cross_mark from 'core/common/assets/images/emoji/cross_mark.png';
import cry from 'core/common/assets/images/emoji/cry.png';
import crystal_ball from 'core/common/assets/images/emoji/crystal_ball.png';
import cyclone from 'core/common/assets/images/emoji/cyclone.png';
import dash from 'core/common/assets/images/emoji/dash.png';
import delivery_truck from 'core/common/assets/images/emoji/delivery_truck.png';
import digit_five from 'core/common/assets/images/emoji/digit_five.png';
import dove from 'core/common/assets/images/emoji/dove.png';
import down_arrow from 'core/common/assets/images/emoji/down_arrow.png';
import droplet from 'core/common/assets/images/emoji/droplet.png';
import eagle from 'core/common/assets/images/emoji/eagle.png';
import face_with_cowboy_hat from 'core/common/assets/images/emoji/face_with_cowboy_hat.png';
import face_with_hand_over_mouth from 'core/common/assets/images/emoji/face_with_hand_over_mouth.png';
import face_with_monocle from 'core/common/assets/images/emoji/face_with_monocle.png';
import face_with_open_eyes_and_hand_over_mouth from 'core/common/assets/images/emoji/face_with_open_eyes_and_hand_over_mouth.png';
import face_with_raised_eyebrow from 'core/common/assets/images/emoji/face_with_raised_eyebrow.png';
import face_with_rolling_eyes from 'core/common/assets/images/emoji/face_with_rolling_eyes.png';
import family from 'core/common/assets/images/emoji/family.png';
import family_man_girl_boy from 'core/common/assets/images/emoji/family_man_girl_boy.png';
import family_woman_girl_girl from 'core/common/assets/images/emoji/family_woman_girl_girl.png';
import fast_forward from 'core/common/assets/images/emoji/fast_forward.png';
import fearful_face from 'core/common/assets/images/emoji/fearful_face.png';
import female_sign from 'core/common/assets/images/emoji/female_sign.png';
import fire from 'core/common/assets/images/emoji/fire.png';
import first_place_medal from 'core/common/assets/images/emoji/first_place_medal.png';
import flexed_biceps from 'core/common/assets/images/emoji/flexed_biceps.png';
import flushed from 'core/common/assets/images/emoji/flushed.png';
import fork_and_knife_with_plate from 'core/common/assets/images/emoji/fork_and_knife_with_plate.png';
import frame_with_picture from 'core/common/assets/images/emoji/frame_with_picture.png';
import glowing_star from 'core/common/assets/images/emoji/glowing_star.png';
import grimacing_face from 'core/common/assets/images/emoji/grimacing_face.png';
import hammer_and_wrench from 'core/common/assets/images/emoji/hammer_and_wrench.png';
import hands_heart from 'core/common/assets/images/emoji/hands_heart.png';
import handshake from 'core/common/assets/images/emoji/handshake.png';
import heart_hands from 'core/common/assets/images/emoji/heart_hands.png';
import hiking_boot from 'core/common/assets/images/emoji/hiking_boot.png';
import hourglass from 'core/common/assets/images/emoji/hourglass.png';
import house from 'core/common/assets/images/emoji/house.png';
import input_numbers from 'core/common/assets/images/emoji/input_numbers.png';
import jigsaw from 'core/common/assets/images/emoji/jigsaw.png';
import kiss from 'core/common/assets/images/emoji/kiss.png';
import knife_fork_plate from 'core/common/assets/images/emoji/knife_fork_plate.png';
import leaf_fluttering_in_wind from 'core/common/assets/images/emoji/leaf_fluttering_in_wind.png';
import ledger from 'core/common/assets/images/emoji/ledger.png';
import loudspeaker from 'core/common/assets/images/emoji/loudspeaker.png';
import love_letter from 'core/common/assets/images/emoji/love_letter.png';
import lying_face from 'core/common/assets/images/emoji/lying_face.png';
import magnifying_glass_tilted_left from 'core/common/assets/images/emoji/magnifying_glass_tilted_left.png';
import male_sign from 'core/common/assets/images/emoji/male_sign.png';
import man from 'core/common/assets/images/emoji/man.png';
import man_student from 'core/common/assets/images/emoji/man_student.png';
import microscope from 'core/common/assets/images/emoji/microscope.png';
import money_with_wings from 'core/common/assets/images/emoji/money_with_wings.png';
import mountain from 'core/common/assets/images/emoji/mountain.png';
import nail_polish from 'core/common/assets/images/emoji/nail_polish.png';
import national_park from 'core/common/assets/images/emoji/national_park.png';
import nazar_amulet from 'core/common/assets/images/emoji/nazar_amulet.png';
import nerd_face from 'core/common/assets/images/emoji/nerd_face.png';
import neutral_face from 'core/common/assets/images/emoji/neutral_face.png';
import new_moon from 'core/common/assets/images/emoji/new_moon.png';
import no_mouth from 'core/common/assets/images/emoji/no_mouth.png';
import old_woman from 'core/common/assets/images/emoji/old_woman.png';
import paperclip from 'core/common/assets/images/emoji/paperclip.png';
import parachute from 'core/common/assets/images/emoji/parachute.png';
import party_popper from 'core/common/assets/images/emoji/party_popper.png';
import partying_face from 'core/common/assets/images/emoji/partying_face.png';
import pensive from 'core/common/assets/images/emoji/pensive.png';
import people_hugging from 'core/common/assets/images/emoji/people_hugging.png';
import pinching_hand from 'core/common/assets/images/emoji/pinching_hand.png';
import pleading_face from 'core/common/assets/images/emoji/pleading_face.png';
import present from 'core/common/assets/images/emoji/present.png';
import raising_hands from 'core/common/assets/images/emoji/raising_hands.png';
import red_heart from 'core/common/assets/images/emoji/red-heart.png';
import relieved from 'core/common/assets/images/emoji/relieved.png';
import revolving_hearts from 'core/common/assets/images/emoji/revolving_hearts.png';
import ribbon from 'core/common/assets/images/emoji/ribbon.png';
import ring from 'core/common/assets/images/emoji/ring.png';
import rised_hand from 'core/common/assets/images/emoji/rised_hand.png';
import rose from 'core/common/assets/images/emoji/rose.png';
import scales from 'core/common/assets/images/emoji/scales.png';
import seedling from 'core/common/assets/images/emoji/seedling.png';
import shield from 'core/common/assets/images/emoji/shield.png';
import slightly_frowning_face from 'core/common/assets/images/emoji/slightly_frowning_face.png';
import slightly_smiling_face from 'core/common/assets/images/emoji/slightly_smiling_face.png';
import smiley from 'core/common/assets/images/emoji/smiley.png';
import smiling_face_with_hearts from 'core/common/assets/images/emoji/smiling-face-with-hearts.png';
import smiling_face from 'core/common/assets/images/emoji/smiling_face.png';
import smiling_face_with_heart_eyes from 'core/common/assets/images/emoji/smiling_face_with_heart_eyes.png';
import smiling_face_with_smiling_eyes from 'core/common/assets/images/emoji/smiling_face_with_smiling_eyes.png';
import smiling_face_with_tear from 'core/common/assets/images/emoji/smiling_face_with_tear.png';
import sparkles from 'core/common/assets/images/emoji/sparkles.png';
import sparkling_heart from 'core/common/assets/images/emoji/sparkling_heart.png';
import speech_balloon from 'core/common/assets/images/emoji/speech_balloon.png';
import star from 'core/common/assets/images/emoji/star.png';
import star_struck from 'core/common/assets/images/emoji/star_struck.png';
import sun_behind_cloud from 'core/common/assets/images/emoji/sun_behind_cloud.png';
import sunglasses from 'core/common/assets/images/emoji/sunglasses.png';
import sunset from 'core/common/assets/images/emoji/sunset.png';
import tangerine from 'core/common/assets/images/emoji/tangerine.png';
import technologist from 'core/common/assets/images/emoji/technologist.png';
import teddy_bear from 'core/common/assets/images/emoji/teddy_bear.png';
import telescope from 'core/common/assets/images/emoji/telescope.png';
import thinking_face from 'core/common/assets/images/emoji/thinking_face.png';
import thumbs_down from 'core/common/assets/images/emoji/thumbs_down.png';
import thumbs_middle from 'core/common/assets/images/emoji/thumbs_middle.png';
import thumbs_more_down from 'core/common/assets/images/emoji/thumbs_more_down.png';
import thumbs_more_up from 'core/common/assets/images/emoji/thumbs_more_up.png';
import thumbs_up from 'core/common/assets/images/emoji/thumbs_up.png';
import two_hearts from 'core/common/assets/images/emoji/two-hearts.png';
import unamused from 'core/common/assets/images/emoji/unamused.png';
import upside_down_face from 'core/common/assets/images/emoji/upside_down_face.png';
import waving_hand from 'core/common/assets/images/emoji/waving_hand.png';
import wheel_of_dharma from 'core/common/assets/images/emoji/wheel_of_dharma.png';
import wink from 'core/common/assets/images/emoji/wink.png';
import womanGesturingNo from 'core/common/assets/images/emoji/woman-gesturing-no.png';
import womanHeartMan from 'core/common/assets/images/emoji/woman-heart-man.png';
import woman from 'core/common/assets/images/emoji/woman.png';
import woman_feeding_baby from 'core/common/assets/images/emoji/woman_feeding_baby.png';
import woman_shrugging from 'core/common/assets/images/emoji/woman_shrugging.png';
import woman_student from 'core/common/assets/images/emoji/woman_student.png';
import worried from 'core/common/assets/images/emoji/worried.png';
import wrench from 'core/common/assets/images/emoji/wrench.png';
import yawning_face from 'core/common/assets/images/emoji/yawning_face.png';

const emojiMapConst = {
  slightly_smiling_face: {
    src: '🙂',
    url: slightly_smiling_face.src,
  },
  unamused: {
    src: '😒',
    url: unamused.src,
  },
  thinking_face: {
    src: '🤔',
    url: thinking_face.src,
  },
  relieved: {
    src: '😌',
    url: relieved.src,
  },
  face_with_cowboy_hat: {
    src: '🤠',
    url: face_with_cowboy_hat.src,
  },
  pleading_face: {
    src: '🥺',
    url: pleading_face.src,
  },
  wink: {
    src: '😉',
    url: wink.src,
  },
  '100': {
    src: '💯',
    url: hundred.src,
  },
  yawning_face: {
    src: '🥱',
    url: yawning_face.src,
  },
  fast_forward: {
    src: '⏭️',
    url: fast_forward.src,
  },
  jigsaw: {
    src: '🧩',
    url: jigsaw.src,
  },
  frame_with_picture: {
    src: '🖼️',
    url: frame_with_picture.src,
  },
  scales: {
    src: '⚖️',
    url: scales.src,
  },
  smiley: {
    src: '😃',
    url: smiley.src,
  },
  face_with_raised_eyebrow: {
    src: '🤨',
    url: face_with_raised_eyebrow.src,
  },
  fire: {
    src: '🔥',
    url: fire.src,
  },
  sunglasses: {
    src: '😎',
    url: sunglasses.src,
  },
  lying_face: {
    src: '🤥',
    url: lying_face.src,
  },
  ribbon: {
    src: '🎀',
    url: ribbon.src,
  },
  microscope: {
    src: '🔬',
    url: microscope.src,
  },
  telescope: {
    src: '🔭',
    url: telescope.src,
  },
  speech_balloon: {
    src: '💬',
    url: speech_balloon.src,
  },
  upside_down_face: {
    src: '🙃',
    url: upside_down_face.src,
  },
  face_with_hand_over_mouth: {
    src: '🤭',
    url: face_with_hand_over_mouth.src,
  },
  pensive: {
    src: '😔',
    url: pensive.src,
  },
  face_with_monocle: {
    src: '🧐',
    url: face_with_monocle.src,
  },
  partying_face: {
    src: '🥳',
    url: partying_face.src,
  },
  nerd_face: {
    src: '🤓',
    url: nerd_face.src,
  },
  teddy_bear: {
    src: '🧸',
    url: teddy_bear.src,
  },
  parachute: {
    src: '🪂',
    url: parachute.src,
  },
  sparkles: {
    src: '✨',
    url: sparkles.src,
  },
  hiking_boot: {
    src: '🥾',
    url: hiking_boot.src,
  },
  knife_fork_plate: {
    src: '🍽️',
    url: knife_fork_plate.src,
  },
  dash: {
    src: '💨',
    url: dash.src,
  },
  droplet: {
    src: '💧',
    url: droplet.src,
  },
  mountain: {
    src: '⛰️',
    url: mountain.src,
  },
  artist: {
    src: '🧑‍🎨',
    url: artist.src,
  },
  angry: {
    src: '😠',
    url: angry.src,
  },
  pinching_hand: {
    src: '🤏',
    url: pinching_hand.src,
  },
  bicyclist: {
    src: '🚴',
    url: bicyclist.src,
  },
  bar_chart: {
    src: '📊',
    url: bar_chart.src,
  },
  briefcase: {
    src: '💼',
    url: briefcase.src,
  },
  'woman-heart-man': {
    src: '👩‍❤️‍👨',
    url: womanHeartMan.src,
  },
  family: {
    src: '👨‍👩‍👧',
    url: family.src,
  },
  airplane: {
    src: '✈️',
    url: airplane.src,
  },
  house: {
    src: '🏠',
    url: house.src,
  },
  sparkling_heart: {
    src: '💖',
    url: sparkling_heart.src,
  },
  money_with_wings: {
    src: '💸',
    url: money_with_wings.src,
  },
  worried: {
    src: '😟',
    url: worried.src,
  },
  broken_heart: {
    src: '💔',
    url: broken_heart.src,
  },
  flushed: {
    src: '😳',
    url: flushed.src,
  },
  // TODO use another image
  smiling_face_with_tear: {
    src: '🥲',
    url: smiling_face_with_tear.src,
  },
  face_with_rolling_eyes: {
    src: '🙄',
    url: face_with_rolling_eyes.src,
  },
  no_mouth: {
    src: '😶',
    url: no_mouth.src,
  },
  'woman-gesturing-no': {
    src: '🙅‍♀️',
    url: womanGesturingNo.src,
  },
  confused: {
    src: '😕',
    url: confused.src,
  },
  cry: {
    src: '😢',
    url: cry.src,
  },
  male_sign: {
    src: '♂️',
    url: male_sign.src,
  },
  female_sign: {
    src: '♀️️',
    url: female_sign.src,
  },
  movie_camera: {
    src: '🎥️️',
    url: movie_camera.src,
  },
  handshake: {
    src: '🤝️',
    url: handshake.src,
  },
  smiling_face_with_hearts: {
    src: '🥰',
    url: smiling_face_with_hearts.src,
  },
  red_heart: {
    src: '❤️',
    url: red_heart.src,
  },
  blue_heart: {
    src: '💙️',
    url: blue_heart.src,
  },
  wrench: {
    src: '🔧',
    url: wrench.src,
  },
  glowing_star: {
    src: '🌟',
    url: glowing_star.src,
  },
  love_letter: {
    src: '💌',
    url: love_letter.src,
  },
  present: {
    src: '🎁',
    url: present.src,
  },
  alarm_clock: {
    src: '⏰',
    url: alarm_clock.src,
  },
  waving_hand: {
    src: '👋',
    url: waving_hand.src,
  },
  star_struck: {
    src: '🤩',
    url: star_struck.src,
  },
  input_numbers: {
    src: '🔢',
    url: input_numbers.src,
  },
  sunset: {
    src: '🌅',
    url: sunset.src,
  },
  rised_hand: {
    src: '✋',
    url: rised_hand.src,
  },
  two_hearts: {
    src: '💕',
    url: two_hearts.src,
  },
  neutral_face: {
    src: '😐',
    url: neutral_face.src,
  },
  brain: {
    src: '🧠',
    url: brain.src,
  },
  flexed_biceps: {
    src: '💪',
    url: flexed_biceps.src,
  },
  blue_book: {
    src: '📘',
    url: blue_book.src,
  },
  man_student: {
    src: '👨‍🎓',
    url: man_student.src,
  },
  smiling_face_with_heart_eyes: {
    src: '😍',
    url: smiling_face_with_heart_eyes.src,
  },
  woman_feeding_baby: {
    src: '👩‍🍼',
    url: woman_feeding_baby.src,
  },
  fearful_face: {
    src: '😨',
    url: fearful_face.src,
  },
  fork_and_knife_with_plate: {
    src: '🍽️',
    url: fork_and_knife_with_plate.src,
  },
  paperclip: {
    src: '📎',
    url: paperclip.src,
  },
  shield: {
    src: '🛡️',
    url: shield.src,
  },
  smiling_face_with_smiling_eyes: {
    src: '😊',
    url: smiling_face_with_smiling_eyes.src,
  },
  eagle: {
    src: '🦅',
    url: eagle.src,
  },
  hourglass: {
    src: '⌛',
    url: hourglass.src,
  },
  cloud: {
    src: '☁️',
    url: cloud.src,
  },
  sun_behind_cloud: {
    src: '⛅',
    url: sun_behind_cloud.src,
  },
  raising_hands: {
    src: '🙌',
    url: raising_hands.src,
  },
  cityscape_at_dusk: {
    src: '🌆',
    url: cityscape_at_dusk.src,
  },
  national_park: {
    src: '🏞️',
    url: national_park.src,
  },
  anxious_face_with_sweat: {
    src: '😰',
    url: anxious_face_with_sweat.src,
  },
  down_arrow: {
    src: '⬇️',
    url: down_arrow.src,
  },
  check_mark_button: {
    src: '✅',
    url: check_mark_button.src,
  },
  cross_mark: {
    src: '❌',
    url: cross_mark.src,
  },
  loudspeaker: {
    src: '📢',
    url: loudspeaker.src,
  },
  party_popper: {
    src: '🎉',
    url: party_popper.src,
  },

  thumbs_up: {
    src: '👍',
    url: thumbs_up.src,
  },
  thumbs_more_up: {
    src: '👍',
    url: thumbs_more_up.src,
  },
  thumbs_middle: {
    src: '👍',
    url: thumbs_middle.src,
  },
  thumbs_more_down: {
    src: '👎',
    url: thumbs_more_down.src,
  },
  thumbs_down: {
    src: '👎',
    url: thumbs_down.src,
  },
  hammer_and_wrench: {
    src: '🛠️',
    url: hammer_and_wrench.src,
  },
  slightly_frowning_face: {
    src: '🙁',
    url: slightly_frowning_face.src,
  },
  woman: {
    src: '👩',
    url: woman.src,
  },
  man: {
    src: '👨',
    url: man.src,
  },
  delivery_truck: {
    src: '🚚',
    url: delivery_truck.src,
  },
  heart_hands: {
    src: '🫶',
    url: heart_hands.src,
  },
  woman_student: {
    src: '👩‍🎓',
    url: woman_student.src,
  },
  revolving_hearts: {
    src: '💞',
    url: revolving_hearts.src,
  },
  people_hugging: {
    src: '💞',
    url: people_hugging.src,
  },
  star: {
    src: '⭐',
    url: star.src,
  },
  first_place_medal: {
    src: '🥇',
    url: first_place_medal.src,
  },
  wheel_of_dharma: {
    src: '☸️',
    url: wheel_of_dharma.src,
  },
  aquarius: {
    src: '♒',
    url: aquarius.src,
  },
  ring: {
    src: '💍',
    url: ring.src,
  },
  hands_heart: {
    src: '🫶',
    url: hands_heart.src,
  },
  kiss: {
    src: '💏',
    url: kiss.src,
  },
  ledger: {
    src: '📒',
    url: ledger.src,
  },
  nail_polish: {
    src: '💅',
    url: nail_polish.src,
  },
  leaf_fluttering_in_wind: {
    src: '🍃',
    url: leaf_fluttering_in_wind.src,
  },
  crystal_ball: {
    src: '🔮',
    url: crystal_ball.src,
  },
  technologist: {
    src: '🧑‍💻',
    url: technologist.src,
  },
  digit_five: {
    src: '5️',
    url: digit_five.src,
  },
  nazar_amulet: {
    src: '🧿',
    url: nazar_amulet.src,
  },
  cyclone: {
    src: '🌀',
    url: cyclone.src,
  },
  bullseye: {
    src: '🎯',
    url: bullseye.src,
  },
  smiling_face: {
    src: '☺️',
    url: smiling_face.src,
  },
  woman_shrugging: {
    src: '🤷‍♀️',
    url: woman_shrugging.src,
  },
  old_woman: {
    src: '👵',
    url: old_woman.src,
  },
  family_woman_girl_girl: {
    src: '👩‍👧‍👧',
    url: family_woman_girl_girl.src,
  },
  family_man_girl_boy: {
    src: '👨‍👧‍👦',
    url: family_man_girl_boy.src,
  },
  magnifying_glass_tilted_left: {
    src: '🔍',
    url: magnifying_glass_tilted_left.src,
  },
  seedling: {
    src: '🌱',
    url: seedling.src,
  },
  face_with_open_eyes_and_hand_over_mouth: {
    src: '🫢',
    url: face_with_open_eyes_and_hand_over_mouth.src,
  },
  grimacing_face: {
    src: '😬',
    url: grimacing_face.src,
  },
  rose: {
    src: '🌹',
    url: rose.src,
  },
  tangerine: {
    src: '🍊',
    url: tangerine.src,
  },
  chocolate_bar: {
    src: '🍫',
    url: chocolate_bar.src,
  },
  compass: {
    src: '🧭',
    url: compass.src,
  },
  cherry_blossom: {
    src: '🌸',
    url: cherry_blossom.src,
  },
  dove: {
    src: '🕊️',
    url: dove.src,
  },
  new_moon: {
    src: '🌑️',
    url: new_moon.src,
  },
};

export const emojiMap: Record<string, { src: string; url: string }> = emojiMapConst;
