import { TFunction } from 'i18next';
import { FormTypes, PaymentFormParams } from 'core/payments/entities';

export const defaultParams = (t?: TFunction): PaymentFormParams => ({
  formParams: {
    submitButtonText: t ? t('form.default.submitButtonText') : 'Start 7-Day Trial',
    googleFontLink: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap',
    isCardHolderVisible: true,
  },
  styles: {
    submit_button: {
      'font-size': '14px',
      background: '#69B13F',
      'border-radius': '30px',
      height: '49px',
      ':disabled': {
        'background-color': '#ACB2BC',
      },
    },
    input_group: {
      '.input-block': {
        flex: 'none',
      },
    },
  },
});

export const experimentParams = (t?: TFunction): PaymentFormParams => ({
  formParams: {
    submitButtonText: t ? t('form.default.submitButtonText') : 'Start 7-Day Trial',
    googleFontLink: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap',
    formTypeClass: FormTypes.Flat,
  },
  styles: {
    submit_button: {
      'font-size': '14px',
      background: '#27AE60',
      'border-radius': '8px',
      height: '49px',
      'box-shadow': 'none',
      ':disabled': {
        'background-color': '#ACB2BC',
      },
    },
    input_group: {
      '.input-block': {
        flex: 'none',
        input: {
          ':focus': {
            'border-color': '#333a4999',
            '~.label': {
              color: '#333a4999',
            },
          },
        },
      },
    },
  },
});
