import image from 'core/common/assets/images/payment-errors/processing.svg';
import Image from 'core/common/components/Image';
import { config } from 'core/config';
import { ImageWrapper, Root, Solution, Title, Caption, RetryButton, Link } from './styled';

export type PaymentErrorProcessingProps = {
  className?: string;
  onTryAgain: () => void;
};

const PaymentErrorProcessing = ({ className, onTryAgain }: PaymentErrorProcessingProps) => {
  return (
    <Root className={className}>
      <ImageWrapper>
        <Image width={80} height={80} src={image} alt="processing-error" />
      </ImageWrapper>
      <Title>An error processing your order.</Title>
      <Solution>Please try again.</Solution>
      <Caption>
        If you have any difficulties with solving this problem, do not hesitate to contact{' '}
        <Link href={config.supportCenter} target="_blank" rel="noreferrer">
          our support
        </Link>
        .
      </Caption>
      <RetryButton onClick={onTryAgain}>Try again</RetryButton>
    </Root>
  );
};

export default PaymentErrorProcessing;
