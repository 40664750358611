import styled from 'styled-components';

const PaymentErrorTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: ${(props) => props.theme.colors.typography700};
`;

export default PaymentErrorTitle;
