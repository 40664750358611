import { useEffect } from 'react';
import { TikTokScriptsService } from '../services';

export const useInitTikTokPixels = (pixels: Array<string>) => {
  useEffect(() => {
    const fragment = document.createDocumentFragment();

    pixels.forEach((pixel) => {
      const script = document.createElement('script');
      script.textContent = TikTokScriptsService.getPixelCode(pixel);
      script.id = `tiktok-pixel-${pixel}`;

      const scriptExists = document.getElementById(`tiktok-pixel-${pixel}`);

      if (scriptExists) {
        return;
      }

      fragment.appendChild(script);
    });

    document.body.appendChild(fragment);
    // Append script just once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
