import styled from 'styled-components';
import Icon from 'core/svg-sprites/ui/Icon';
import { ColorVariants } from 'core/theme/entities';
import ButtonBack from '../ButtonBack';

export const ContainerStyled = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: 13px;
  padding-top: 20px;
  text-align: center;
`;

export const IconStyled = styled(Icon)<{ variant: ColorVariants }>`
  fill: ${({ theme, variant }) => {
    if (variant === 'secondary') {
      return theme.colors.typography100;
    }

    return theme.colors.typography700;
  }};
`;

export const BackButtonStyled = styled(ButtonBack)<{ variant?: ColorVariants }>`
  position: absolute;
  top: 20px;
  left: 0;
  width: 24px;
  height: 24px;
  fill: ${({ theme, variant }) => {
    if (variant === 'secondary') {
      return theme.colors.typography100;
    }

    return theme.colors.typography700;
  }};
`;
