import styled from 'styled-components';

export const WrapperStyled = styled.div`
  position: relative;
  overflow-y: auto;
`;

export const TitleStyled = styled.h4`
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  text-align: center;
`;
