import { memo, useEffect, useRef } from 'react';
import {
  PaymentError,
  PaymentFormPaypalData,
  PaymentOrderDescription,
  PaypalProcessingError,
} from 'core/payments/entities';
import { Paypal } from './styled';

export type PaymentPaypalProps = {
  className?: string;
  url: PaymentFormPaypalData | null;
  onFailed: (data: PaymentError) => void;
  onOrderStartProcessing: () => void;
  onSucceed: (data: { order?: PaymentOrderDescription }) => void;
};

const PaymentPaypal = (props: PaymentPaypalProps) => {
  const { className, onSucceed, onFailed, onOrderStartProcessing, url } = props;

  const buttonId = useRef('paypal-button');
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!url) return;

    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-btn-id', buttonId.current);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  useEffect(() => {
    const buttonContainer = buttonRef.current;

    if (!buttonContainer) return () => {};

    const eventListenersMap = {
      'order-started-processing': () => {
        onOrderStartProcessing();
      },
      'order-processed': (event: Event) => {
        const customEvent = event as CustomEvent<{
          data: { order: PaymentOrderDescription; error: PaypalProcessingError };
        }>;
        const { data } = customEvent.detail;

        if (data.error && data.error.code) {
          onFailed({
            message: data.error.recommended_message_for_user,
            details: { event: customEvent },
          });
          return;
        }
        onSucceed(data);
      },
      'button-error': (event: Event) => {
        onFailed({
          message: 'Paypal payment error',
          details: { event },
        });
      },
    };

    Object.entries(eventListenersMap).forEach(([type, listener]) =>
      buttonContainer.addEventListener(type, listener, false),
    );

    return () =>
      Object.entries(eventListenersMap).forEach(([type, listener]) =>
        buttonContainer.removeEventListener(type, listener, false),
      );
  }, [url, onOrderStartProcessing, onSucceed, onFailed]);

  return <Paypal id={buttonId.current} ref={buttonRef} className={className} />;
};

export default memo(PaymentPaypal);
