import { useCallback } from 'react';
import { useDispatch } from 'core/common/hooks';
import { fetchOrderByOfferIdRequest } from '../store/actions';

export function usePaymentOrder() {
  const dispatch = useDispatch();

  const doFetchOrderByOfferId = useCallback(
    (offerId: string) => {
      dispatch(fetchOrderByOfferIdRequest(offerId));
    },
    [dispatch],
  );

  return {
    fetchOrderByOfferId: doFetchOrderByOfferId,
  };
}
