import { useServices } from 'core/common/hooks';
import { useUserLocation } from 'core/user/hooks';
import { useProductDescription } from './useProductDescription';

export function useProduct(productName: string) {
  const { product, error, loadingStatus } = useProductDescription(productName);

  const { taxService } = useServices();

  const { isEuropean } = useUserLocation();

  const getProductPrice = () => {
    const productPrice = product?.trialPrice ?? product?.price ?? 0;
    return productPrice === 0.01 ? 0 : productPrice;
  };

  const getValueAddedTax = (value: number) => {
    return taxService.getValueAddedTax(value);
  };

  const getPriceWithoutVAT = (value: number) => {
    if (!isEuropean) return value;
    return taxService.getPriceWithoutVAT(value);
  };

  return {
    fullPrice: product?.price || 0,
    product,
    price: getProductPrice(),
    error,
    loadingStatus,
    vat: getValueAddedTax(getProductPrice()),
    priceWithoutVAT: getPriceWithoutVAT(getProductPrice()),
    period: product?.period || '',
    secretPrice: product?.price ?? 9.99,
  };
}
