import image from 'core/common/assets/images/payment-errors/debit-card.svg';
import Image from 'core/common/components/Image';
import { config } from 'core/config';
import { ImageWrapper, Root, Solution, Title, Caption, RetryButton, Link } from './styled';

export type PaymentErrorDebitCardProps = {
  className?: string;
  onTryAgain: () => void;
};

const PaymentErrorDebitCard = ({ className, onTryAgain }: PaymentErrorDebitCardProps) => {
  return (
    <Root className={className}>
      <ImageWrapper>
        <Image width={80} height={80} src={image} alt="debit-card" />
      </ImageWrapper>
      <Title>Debit cards don’t support online payments.</Title>
      <Solution>Please, try using a credit card or choose another payment method.</Solution>
      <Caption>
        If you have any difficulties with solving this problem, do not hesitate to contact{' '}
        <Link href={config.supportCenter} target="_blank" rel="noreferrer">
          our support
        </Link>
        .
      </Caption>
      <RetryButton onClick={onTryAgain}>Try another method</RetryButton>
    </Root>
  );
};

export default PaymentErrorDebitCard;
