import { config } from 'core/config';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { DisclaimerStyled, LinkStyled } from './styled';

const CardPaymentDisclaimer = () => {
  return (
    <Location>
      <LocationOption option={Locales.BR}>
        <DisclaimerStyled>
          This transaction will be processed by{' '}
          <LinkStyled href={config.ebanxUrl} target="_blank" rel="noopener noreferrer">
            EBANX
          </LinkStyled>{' '}
          according to their terms. By continuing you acknowledge and accept these terms and
          conditions. In the case of multiple cards (credit/debit) the charge will be using the
          credit function.
        </DisclaimerStyled>
      </LocationOption>
    </Location>
  );
};

export default CardPaymentDisclaimer;
