import { forwardRef, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

export type ModalFadeProps = {
  children?: ReactNode;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
  className?: string;
};

const ModalFade = forwardRef<HTMLDivElement, ModalFadeProps>((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: {
      opacity: 0,
      outline: 'none',
    },
    to: {
      opacity: open ? 1 : 0,
      outline: 'none',
    },
    immediate: !open,
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default ModalFade;
