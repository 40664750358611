import { useContext } from 'react';
import { QuizContext } from 'core/funnel/contexts';

/** @deprecated use useQuizStep and others hooks **/
export const useQuizData = () => {
  const { userPreferences, offers, link } = useContext(QuizContext);

  return {
    userPreferences,
    link,
    offers,
  };
};
