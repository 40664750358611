import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'core/common/hooks';
import { clearPaymentState, recurringPaymentRequest } from '../store/actions';
import { getPaymentError, getPaymentProcessStep } from '../store/selectors';

export function useRecurringPayment() {
  const dispatch = useDispatch();

  const paymentProcessStep = useSelector(getPaymentProcessStep);
  const error = useSelector(getPaymentError);

  const doRecurringPaymentRequest = useCallback(
    (offerId: string) => {
      dispatch(recurringPaymentRequest(offerId));
    },
    [dispatch],
  );

  const clearState = useCallback(() => {
    dispatch(clearPaymentState());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  return {
    recurringPaymentRequest: doRecurringPaymentRequest,
    paymentProcessStep,
    error,
    clearState,
  };
}
