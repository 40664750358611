import { ModalProps as MountedModalProps } from '../Modal';
import { StyledMountedModal } from './styled';

const MountedModal = ({ children, className, open = false, ...otherProps }: MountedModalProps) => {
  return (
    <StyledMountedModal
      open={open}
      disableScrollLock
      isHidden={!open}
      {...otherProps}
      className={className}
    >
      {children}
    </StyledMountedModal>
  );
};

export default MountedModal;
