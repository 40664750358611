import styled from 'styled-components';
import { AppLoaderVariant } from './types';

export const BackdropStyled = styled.div<{ variant: AppLoaderVariant }>`
  ${({ variant, theme }) => {
    if (variant === 'filled') {
      return { background: theme.colors.white };
    }

    return { backdropFilter: 'blur(10px)' };
  }}
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
