import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingStatuses } from 'core/common/entities';
import { useDispatch } from 'core/common/hooks';
import { useQuizData } from 'core/funnel/hooks';
import {
  fetchOffersByCategory,
  getBlackMoonUpsellOffer,
  getConsultationUpsellOffer,
  getDefaultOffers,
  getDiscountDefaultOffers,
  getDiscountOffer,
  getNatalChartUpsellOffer,
  getNumerologyUpsellsOffer,
  getOffersLoadingStatus,
  getPalmistryUpsellsOffer,
  getPremiumUpsellOffer,
  getSelectedOffer,
  getTarotUpsellsOffer,
  getTimeUpOffer,
  getTimeUpOffers,
  getTrialCreditsOffer,
  getUltraPackOffer,
  selectedOfferUpdated,
  getDiscountConsultationUpsellOffer,
  getReadingsPackUpsellOffer,
  getDiscountUltraPackOffer,
  getCheapestOffer,
} from 'core/offers/store';
import { Offer } from '../entities';

export function useOffers() {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getOffersLoadingStatus);

  const defaultOffers = useSelector(getDefaultOffers);
  const timeUpOffers = useSelector(getTimeUpOffers);
  const discountDefaultOffers = useSelector(getDiscountDefaultOffers);
  const trialCreditsOffer = useSelector(getTrialCreditsOffer);
  const ultraPackOffer = useSelector(getUltraPackOffer);
  const discountUltraPackOffer = useSelector(getDiscountUltraPackOffer);
  const discountOffer = useSelector(getDiscountOffer);
  const tarotUpsellsOffer = useSelector(getTarotUpsellsOffer);
  const palmistryUpsellsOffer = useSelector(getPalmistryUpsellsOffer);
  const numerologyUpsellsOffer = useSelector(getNumerologyUpsellsOffer);
  const timeUpOffer = useSelector(getTimeUpOffer);
  const selectedOffer = useSelector(getSelectedOffer);
  const premiumUpsellOffer = useSelector(getPremiumUpsellOffer);
  const natalChartUpsellOffer = useSelector(getNatalChartUpsellOffer);
  const blackMoonUpsellOffer = useSelector(getBlackMoonUpsellOffer);
  const consultationUpsellOffer = useSelector(getConsultationUpsellOffer);
  const discountConsultationUpsellOffer = useSelector(getDiscountConsultationUpsellOffer);
  const readingsPackUpsellOffer = useSelector(getReadingsPackUpsellOffer);
  const cheapestOffer = useSelector(getCheapestOffer);

  const { offers } = useQuizData();

  useEffect(() => {
    if (loadingStatus !== LoadingStatuses.IDLE) return;

    dispatch(fetchOffersByCategory(offers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStatus]);

  const doFetchOffersByCategory = useCallback(() => {
    dispatch(fetchOffersByCategory(offers));
  }, [dispatch, offers]);

  const updateOffer = useCallback(
    (offer: Offer) => {
      dispatch(selectedOfferUpdated(offer));
    },
    [dispatch],
  );

  return {
    defaultOffers,
    timeUpOffers,
    discountOffer,
    tarotUpsellsOffer,
    palmistryUpsellsOffer,
    numerologyUpsellsOffer,
    trialCreditsOffer,
    ultraPackOffer,
    discountUltraPackOffer,
    updateOffer,
    loadingStatus,
    timeUpOffer,
    selectedOffer,
    premiumUpsellOffer,
    natalChartUpsellOffer,
    blackMoonUpsellOffer,
    fetchOffersByCategory: doFetchOffersByCategory,
    discountDefaultOffers,
    consultationUpsellOffer,
    discountConsultationUpsellOffer,
    readingsPackUpsellOffer,
    cheapestOffer,
  };
}
