import styled from 'styled-components';
import ButtonClose from 'core/common/components/ButtonClose';
import MountedModal from 'core/common/components/MountedModal';

export const MountedModalStyled = styled(MountedModal)<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

export const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 95vw;
  max-height: 95vh;
  padding: 20px 15px 30px;
  border-radius: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.textPrimary};
  background: ${({ theme }) => theme.colors.white};
`;

export const CloseButtonStyled = styled(ButtonClose)`
  width: fit-content;
`;
