import { useCallback, useEffect } from 'react';
import { useServices } from 'core/common/hooks';
import { useIsApple } from 'core/common/hooks/useIsApple';
import { useFunnelName } from 'core/funnel/hooks';
import {
  CardPaymentEntities,
  PaymentError,
  PaymentOrderDescription,
  PaymentProcessSteps,
  PaymentType,
} from 'core/payments/entities';
import { usePayment, useOrder } from 'core/payments/hooks';
import { Currency } from 'core/user/entities';
import { useUser } from 'core/user/hooks';

export type UsePaymentFormProps = {
  productId: string;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
  onPaymentFailed?: () => void;
  currency?: Currency;
};

export const usePaymentForm = ({
  onPaymentFailed,
  onPaymentSuccess,
  productId,
  currency = Currency.USD,
}: UsePaymentFormProps) => {
  const { analyticsService, loggingService: logger, observabilitySystem } = useServices();
  const { orderId } = useOrder();

  const { userPreferences } = useUser();
  const { email } = userPreferences;

  const { isApple } = useIsApple();
  const { funnelName } = useFunnelName();

  const {
    initializePaymentFormRequest,
    paymentFormVerify,
    paymentFormMerchantData,
    paymentFormPaypalData,
    paymentProcessStep,
    paymentError,
    clearPaymentState,
    paymentFormFailed: paymentFormFailedReceived,
  } = usePayment({ offerId: productId });

  const shouldRenderPaypal = !!paymentFormPaypalData;
  const shouldRenderCardPayment = !!paymentFormMerchantData;

  const paymentClick = (paymentType: PaymentType) => {
    analyticsService.paymentClick(paymentType);
  };

  const paypalFailed = useCallback(
    (data: PaymentError) => {
      logger.error(new Error('Paypal error'), {
        data,
        tags: { payment: true },
      });

      analyticsService.paymentError(data.message);
      paymentFormFailedReceived();

      if (onPaymentFailed) {
        onPaymentFailed();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticsService, logger],
  );

  const paymentFormFailed = useCallback(
    (data: PaymentError) => {
      logger.error(new Error('Payment form error'), {
        data,
        tags: { payment: true },
      });

      analyticsService.paymentError(data.message);
      paymentFormFailedReceived();

      if (onPaymentFailed) {
        onPaymentFailed();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticsService, logger],
  );

  const trackOrderCardStartProcessing = (paymentType: CardPaymentEntities) => {
    analyticsService.paymentCardChosen(paymentType);
  };

  const trackOrderPaypalStartProcessing = () => {
    analyticsService.paymentPaypalChosen();
  };

  const paymentSucceed = useCallback(
    (data: { order?: PaymentOrderDescription }, paymentMethod: string) => {
      if (!data.order) {
        logger.warn('Order for succeed payment not found', {
          data,
          tags: { payment: true },
        });

        return;
      }

      const span = observabilitySystem.startSpan('payment_succeed', {
        attributes: {
          type: paymentMethod,
        },
      });
      span.end();

      analyticsService.paymentSuccess({
        orderId,
        paymentProviderOrder: data.order,
        productId,
        paymentMethod,
        email,
        currency,
      });

      if (onPaymentSuccess) {
        onPaymentSuccess(data);
      }
    },
    [
      onPaymentSuccess,
      analyticsService,
      logger,
      productId,
      currency,
      email,
      orderId,
      observabilitySystem,
    ],
  );

  const tryAgain = () => {
    if (paymentProcessStep !== PaymentProcessSteps.FAILED) return;

    analyticsService.paymentRetried();
    initializePaymentFormRequest();
  };

  const cardFormError = useCallback(
    (data: unknown) => {
      logger.error('Payment card form error', {
        data,
        tags: { payment: true },
      });
    },
    [logger],
  );

  useEffect(() => {
    if (orderId) {
      analyticsService.paymentFormOpened(orderId);
    }
  }, [analyticsService, orderId]);

  useEffect(() => {
    initializePaymentFormRequest();

    return () => clearPaymentState();
  }, [clearPaymentState, productId, initializePaymentFormRequest]);

  return {
    paymentError,
    shouldRenderPaypal,
    shouldRenderCardPayment,
    shouldRenderApplePay: isApple,
    paymentProcessStep,
    paymentFormMerchantData,
    paymentFormPaypalData,
    paymentFormFailed,
    paymentSucceed,
    paypalFailed,
    paymentVerify: paymentFormVerify,
    cardFormError,
    paymentClick,
    tryAgain,
    funnelName,

    trackOrderCardStartProcessing,
    trackOrderPaypalStartProcessing,
    initializePaymentFormRequest,
  };
};
