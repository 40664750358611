import CircularProgress from '../CircularProgress';
import RenderOnlyOnClient from '../RenderOnlyOnClient';
import { AppLoaderVariant } from './types';
import { BackdropStyled } from './styled';

export type AppLoaderProps = {
  variant?: AppLoaderVariant;
};

const AppLoader = ({ variant = 'blurred' }: AppLoaderProps) => {
  return (
    <RenderOnlyOnClient>
      <BackdropStyled variant={variant}>
        <CircularProgress />
      </BackdropStyled>
    </RenderOnlyOnClient>
  );
};

export default AppLoader;
