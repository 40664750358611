import { useCallback } from 'react';
import { useDispatch, useSelector } from 'core/common/hooks';
import {
  clearPaymentState,
  initializePaymentFormRequest,
  paymentFormFailed,
  paymentFormVerify,
} from '../store/actions';
import {
  getPaymentError,
  getPaymentFormMerchantData,
  getPaymentFormPaypalData,
  getPaymentProcessStep,
} from '../store/selectors';

type UsePaymentFormProps = {
  offerId: string;
};

export function usePayment({ offerId }: UsePaymentFormProps) {
  const dispatch = useDispatch();

  const paymentProcessStep = useSelector(getPaymentProcessStep);
  const paymentError = useSelector(getPaymentError);
  const paymentFormMerchantData = useSelector(getPaymentFormMerchantData);
  const paymentFormPaypalData = useSelector(getPaymentFormPaypalData);

  const doInitializePaymentFormRequest = useCallback(() => {
    dispatch(initializePaymentFormRequest(offerId));
  }, [dispatch, offerId]);

  const doPaymentFormVerify = useCallback(() => {
    dispatch(paymentFormVerify());
  }, [dispatch]);

  const doPaymentFormFailed = useCallback(() => {
    dispatch(paymentFormFailed());
  }, [dispatch]);

  const doClearPaymentState = useCallback(() => {
    dispatch(clearPaymentState());
  }, [dispatch]);

  return {
    paymentProcessStep,
    paymentError,
    paymentFormMerchantData,
    paymentFormPaypalData,

    initializePaymentFormRequest: doInitializePaymentFormRequest,
    paymentFormVerify: doPaymentFormVerify,
    clearPaymentState: doClearPaymentState,
    paymentFormFailed: doPaymentFormFailed,
  };
}
