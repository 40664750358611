import image from 'core/common/assets/images/payment-errors/insufficient-funds.svg';
import Image from 'core/common/components/Image';
import { config } from 'core/config';
import { ImageWrapper, Root, Solution, Title, Caption, RetryButton, Link } from './styled';

export type PaymentErrorInsufficientFundsProps = {
  className?: string;
  onTryAgain: () => void;
};

const PaymentErrorInsufficientFunds = ({
  className,
  onTryAgain,
}: PaymentErrorInsufficientFundsProps) => {
  return (
    <Root className={className}>
      <ImageWrapper>
        <Image width={80} height={80} src={image} alt="insufficient-funds" />
      </ImageWrapper>
      <Title>Insufficient funds for payment</Title>
      <Solution>Don’t worry, try another payment method or try again</Solution>
      <Caption>
        If you have any difficulties with solving this problem, do not hesitate to contact{' '}
        <Link href={config.supportCenter} target="_blank" rel="noreferrer">
          our support
        </Link>
        .
      </Caption>
      <RetryButton onClick={onTryAgain}>Try another method</RetryButton>
    </Root>
  );
};

export default PaymentErrorInsufficientFunds;
