import i18next from 'i18next';
import { useDispatch } from 'core/common/hooks';
import { changeSystemLanguage } from 'core/common/store/system';
import { Languages } from '../entities';

export const useChangeLanguage = () => {
  const dispatch = useDispatch();

  const changeLanguage = (language: Languages) => {
    i18next.changeLanguage(language);
    dispatch(changeSystemLanguage(language));
  };

  return changeLanguage;
};
