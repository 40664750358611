import { ColorVariants } from 'core/theme/entities';
import HeaderMenu from './HeaderMenu';
import { BackButtonStyled, ContainerStyled, IconStyled } from './styled';

type HeaderProps = {
  onBack?: () => void;
  buttonBack?: boolean;
  variant?: ColorVariants;
  className?: string;
};

const Header = ({
  onBack,
  buttonBack = true,
  variant = 'primary',
  className = '',
}: HeaderProps) => (
  <ContainerStyled className={className}>
    <IconStyled variant={variant} name="nebula-small" height={18} />
    {buttonBack && <BackButtonStyled onClick={onBack} variant={variant} />}
    <HeaderMenu variant={variant} />
  </ContainerStyled>
);

export default Header;
