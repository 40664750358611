import { ReactNode } from 'react';

export type CaseProps = {
  value?: unknown;
  children: ReactNode;
  default?: boolean;
};

const Case = ({ children }: CaseProps) => {
  return <>{children}</>;
};

export default Case;
