import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  useDispatch,
  useLogger,
  usePostMessageToFunnelBuilderOnRouteChange,
  useRedirectToAskNebula,
  useRouter,
  useServices,
} from 'core/common/hooks';
import { RootInitialState } from 'core/common/store/rootReducer';
import { appendQueryParams } from 'core/common/utils/appendQueryParams';
import { evalCondition } from 'core/common/utils/evalCondition';
import { getQueryParams } from 'core/common/utils/getQueryParams';
import { isAbsolutePath } from 'core/common/utils/isAbsolutePath';
import { isObject } from 'core/common/utils/isObject';
import { joinUrls } from 'core/common/utils/joinUrls';
import { redirectToUrl } from 'core/common/utils/redirectToUrl';
import { useFeatureFlags } from 'core/feature-flags/hooks';
import { getExperiments, saveExperiment } from 'core/feature-flags/store';
import { EmailFlowAttributes, isEmailFlow } from 'core/flow/entities';
import {
  BasePageAttributes,
  BasePageAttributesWithMappedSections,
  BaseQuizAttributes,
  FunnelScreenType,
  QuizStepTypes,
  SectionAttributes,
} from 'core/funnel/entities';
import { getConditionAttributes } from 'core/funnel/store';
import { OffersAttributes, OffersByCategory } from 'core/offers/entities';
import { fetchOffersByCategory } from 'core/offers/store';
import { UserProfile } from 'core/user/entities';
import { useUser } from 'core/user/hooks';
import { createOrder, updateUserInformation } from 'core/user/store';
import { useFunnelData } from './FunnelContext';

export type QuizContextData = {
  getScreenAttributes: (id: string) => BaseQuizAttributes | BasePageAttributesWithMappedSections;
  userPreferences: UserProfile;
  updateUserPreferences: (preference: string, preferenceData: unknown) => void;
  completeQuiz: (email: string, productName: string) => Promise<void>;
  trackAnalyticForScreen: (id: string) => void;
  trackAnalyticUserChoice: (event: string, choice: string | string[]) => void;
  updateUserSettings: (emailConsent: boolean) => void;
  extraNext: (id: string) => void;
  next: (args: NextArgs) => Promise<void>;
  back: (id: string) => Promise<void>;
  updateCurrentStep: (id: number) => void;
  link: string;
  offers: OffersByCategory;
  signIn: () => void;
  flowId: string;
};

export const QuizContext = createContext<QuizContextData>(undefined!);

export type FlowProviderProps = {
  children?: ReactNode;
};

export type NextArgs = {
  id: string;
  value?: string | string[];
  force?: boolean;
};

export const FlowProvider = ({ children }: FlowProviderProps) => {
  const { navigate, asPath } = useRouter();
  const dispatch = useDispatch();
  const logger = useLogger();
  const featureFlags = useFeatureFlags();
  const { link, name, flow, analyticsIds } = useFunnelData();

  const { analyticsService, settingsService, templateEngine } = useServices();
  const { redirectToLogin } = useRedirectToAskNebula();

  const storedExperiments = useSelector(getExperiments);
  const { userPreferences, updateUserPreferences: updateDefaultUserPreferences } = useUser();
  const store = useStore<RootInitialState>();

  const screens = flow.screens;

  const navigateTo = (step: string, force?: boolean) => {
    if (isAbsolutePath(step)) {
      return redirectToUrl(appendQueryParams(step, getQueryParams()));
    }

    navigate(joinUrls(link, step), force);
  };

  const getDefaultPageSections = (sections: BasePageAttributes['sections']) => {
    const sectionsByCondition = sections.map((section) => {
      const attrsByCondition = getAttributesByCondition(section.attributes);

      if (!attrsByCondition) {
        const attrsWithoutConditions = section.attributes.filter((attrs) => !attrs.condition);

        return attrsWithoutConditions[attrsWithoutConditions.length - 1];
      }

      return attrsByCondition;
    });

    const sectionsWithMappedContent = sectionsByCondition.map((section) => {
      return {
        ...section,
        content: transformContentToTemplate(section.content),
      };
    });

    return sectionsWithMappedContent as BasePageAttributesWithMappedSections['sections'];
  };

  const getPageSections = (sections: BasePageAttributes['sections']) => {
    const funnelConditions = getConditionAttributes(store.getState());

    const getFilteredAttributes = (attributes: Array<SectionAttributes>) => {
      return attributes.filter(
        (attrs) => attrs.condition && [funnelConditions].find(evalCondition(attrs.condition)),
      );
    };

    const sectionsByCondition = sections.map((section) => {
      const attrsByCondition = getFilteredAttributes(section.attributes);

      if (!attrsByCondition.length) {
        const attrsWithoutConditions = section.attributes.filter((attrs) => !attrs.condition);

        return getAttributesByExperiment(attrsWithoutConditions);
      }

      return getAttributesByExperiment(attrsByCondition);
    });

    const sectionsWithMappedContent = sectionsByCondition.map((section) => {
      return {
        ...section,
        content: transformContentToTemplate(section.content),
      };
    });

    return sectionsWithMappedContent as BasePageAttributesWithMappedSections['sections'];
  };

  const getAttributesByCondition = <
    TAttributes extends BaseQuizAttributes | BasePageAttributes | SectionAttributes,
  >(
    attributes: Array<TAttributes>,
  ): TAttributes => {
    const funnelConditions = getConditionAttributes(store.getState());

    const attrsByCondition = attributes.find((attrs) => {
      if (attrs.condition) {
        return [funnelConditions].find(evalCondition(attrs.condition));
      }
    });

    return attrsByCondition ?? attributes[attributes.length - 1];
  };

  const getAttributesByExperiment = <
    TAttributes extends BaseQuizAttributes | BasePageAttributes | SectionAttributes,
  >(
    attributes: Array<TAttributes>,
  ): TAttributes => {
    const attributesWithExperiment = attributes.filter((attrs) => attrs.experiment);
    const attributesWithoutExperiment = attributes.filter((attrs) => !attrs.experiment);

    if (!attributesWithExperiment.length) {
      return getAttributesByCondition(attributes);
    }

    const [experimentName] = Object.keys(attributesWithExperiment[0].experiment!);

    const findMatchingAttributes = (group: string) => {
      return attributesWithExperiment.find((item) =>
        [{ [experimentName]: group }].find(evalCondition(item.experiment)),
      );
    };

    // Return attributes based on experiment from storage
    if (storedExperiments[experimentName]) {
      const matchedAttributes = findMatchingAttributes(storedExperiments[experimentName]);

      return (
        matchedAttributes ?? attributesWithoutExperiment[attributesWithoutExperiment.length - 1]
      );
    }

    const experimentGroup = featureFlags.getExperimentGroup(experimentName);

    // Put experiment into the storage if it is not there yet
    if (experimentGroup && !storedExperiments[experimentName]) {
      dispatch(saveExperiment({ name: experimentName, group: experimentGroup as string }));
    }

    const attributesMatchedWithExperimentGroup = findMatchingAttributes(experimentGroup as string);

    return attributesMatchedWithExperimentGroup ?? getAttributesByCondition(attributes);
  };

  const getPageAttributes = (
    attributes: Array<BasePageAttributes>,
  ): BasePageAttributesWithMappedSections => {
    const funnelConditions = getConditionAttributes(store.getState());

    const screensWithCondition = attributes.filter((item) => {
      if (item.condition) {
        return !![funnelConditions].find(evalCondition(item.condition));
      }

      return false;
    });

    if (screensWithCondition.length) {
      const experimentBasedAttributesWithCondition =
        getAttributesByExperiment(screensWithCondition);

      return {
        ...experimentBasedAttributesWithCondition,
        sections: getPageSections(experimentBasedAttributesWithCondition.sections),
      };
    }

    const screensWithoutCondition = attributes.filter((item) => !item.condition);

    const experimentBasedAttributes = getAttributesByExperiment(screensWithoutCondition);

    return {
      ...experimentBasedAttributes,
      sections: getPageSections(experimentBasedAttributes.sections),
    };
  };

  const getQuizStepAttributes = (attributes: Array<BaseQuizAttributes>): BaseQuizAttributes => {
    const funnelConditions = getConditionAttributes(store.getState());

    const screensWithCondition = attributes.filter((item) => {
      if (item.condition) {
        return !![funnelConditions].find(evalCondition(item.condition));
      }

      return false;
    });

    if (screensWithCondition.length) {
      const experimentBasedAttributesWithCondition =
        getAttributesByExperiment(screensWithCondition);

      return {
        ...experimentBasedAttributesWithCondition,
        content: transformContentToTemplate(experimentBasedAttributesWithCondition.content),
      };
    }

    const screensWithoutCondition = attributes.filter((item) => !item.condition);

    const experimentBasedAttributes = getAttributesByExperiment(screensWithoutCondition);

    return {
      ...experimentBasedAttributes,
      content: transformContentToTemplate(experimentBasedAttributes.content),
    };
  };

  const checkIfAttributesContainExperiment = (
    attributes: Array<BasePageAttributes | BaseQuizAttributes>,
  ) => {
    return attributes.some((item) => {
      if (item.experiment) {
        return true;
      }

      if ('sections' in item) {
        return item.sections.some((section) => section.attributes.some((attr) => attr.experiment));
      }

      return false;
    });
  };

  const getDefaultAttributes = (
    attributes: Array<BaseQuizAttributes | BasePageAttributes>,
  ): BaseQuizAttributes | BasePageAttributesWithMappedSections => {
    if (attributes.every((attr) => 'sections' in attr)) {
      return {
        ...attributes[0],
        sections: getDefaultPageSections(attributes[0].sections),
      };
    }

    return attributes[0] as BaseQuizAttributes;
  };

  const getScreenAttributes = (
    pageId: string,
  ): BaseQuizAttributes | BasePageAttributesWithMappedSections => {
    const screen = screens.find((item) => item.id === pageId);
    const isNextScreen = asPath.split('/').pop() !== pageId;

    if (!screen) {
      // TODO: may need to call "checkIfAttributesContainExperiment" in this conditional statement
      logger.warn('Screen attributes not found');

      const initialScreen =
        screens.find((screenItem) => screenItem.id === flow.initialScreen) || screens[0];
      const isPage = initialScreen.type === FunnelScreenType.PAGE;

      return isPage
        ? getPageAttributes(initialScreen.attributes as Array<BasePageAttributes>)
        : getQuizStepAttributes(initialScreen.attributes as Array<BaseQuizAttributes>);
    }

    const screenType = screen.type ?? FunnelScreenType.QUIZ_STEP;
    const screenAttributes = screen.attributes as Array<BasePageAttributes | BaseQuizAttributes>;
    const screenWithExperiment = checkIfAttributesContainExperiment(
      screenAttributes as Array<BasePageAttributes | BaseQuizAttributes>,
    );

    if (isNextScreen && screenWithExperiment) {
      return getDefaultAttributes(
        screenAttributes as Array<BaseQuizAttributes | BasePageAttributes>,
      );
    }

    // TODO: remove explicit type casting when "type" filed won't be optional
    return screenType === FunnelScreenType.PAGE
      ? getPageAttributes(screen.attributes as Array<BasePageAttributes>)
      : getQuizStepAttributes(screen.attributes as Array<BaseQuizAttributes>);
  };

  const transformContentToTemplate = (content: Record<string, unknown>) => {
    const copyContent = { ...content };

    Object.entries(copyContent).forEach(([key, value]) => {
      if (typeof value === 'string') {
        copyContent[key] = getTemplate(value);
        return;
      }

      if (Array.isArray(value)) {
        copyContent[key] = value.map((item) => {
          if (typeof item === 'string') {
            return getTemplate(item);
          }

          return transformContentToTemplate(item);
        });

        return;
      }

      if (isObject(value)) {
        copyContent[key] = transformContentToTemplate(value as Record<string, unknown>);
      }
    });

    return copyContent;
  };

  const getEmailFlow = useCallback(
    (emailFlowAttributes: EmailFlowAttributes['emailFlowAttributes']): string => {
      const flowConditions = getConditionAttributes(store.getState());

      if (!emailFlowAttributes || !emailFlowAttributes.emailFlow) {
        return '';
      }

      const emailFlow = emailFlowAttributes.emailFlow.find(isEmailFlow);

      if (!emailFlow) {
        return '';
      }

      const emailFlowNamesWithoutCondition = emailFlow.attributes
        .filter((attribute) => !attribute.condition)
        .map((attribute) => attribute.name);

      const emailFlowNamesWithCondition = emailFlow.attributes
        .filter((attribute) => [flowConditions].find(evalCondition(attribute.condition)))
        .map((attribute) => attribute.name);

      if (emailFlowNamesWithCondition.length === 1) {
        return emailFlowNamesWithCondition[0];
      }

      if (emailFlowNamesWithCondition.length > 1) {
        return '';
      }

      if (emailFlowNamesWithoutCondition.length === 1) {
        return emailFlowNamesWithoutCondition[0];
      }

      return '';
    },
    // Ignoring store as a dependency, because conditionAttributes are not dynamic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const emailFlow = useMemo(
    () => getEmailFlow(flow.attributes.emailFlowAttributes),
    [flow, getEmailFlow],
  );

  const emailFlowReports = flow.attributes.emailFlowAttributes?.reports;

  const getTemplate = (value: string) => {
    const template = templateEngine.compile(value);
    return template(getConditionAttributes(store.getState()));
  };

  const updateUserSettings = async (emailConsent: boolean) => {
    await settingsService.setEmailConsent(emailConsent);
  };

  const getNextScreen = (id: string) => {
    const { next } = getScreenAttributes(id);

    if (!next) return '';

    if (getScreenAttributes(next).screenType !== QuizStepTypes.SPLIT) return next;

    return getScreenAttributes(next).next;
  };

  const getExtraNextScreen = (id: string) => {
    const { extraNext } = getScreenAttributes(id);

    if (!extraNext) return '';

    if (getScreenAttributes(extraNext).screenType !== QuizStepTypes.SPLIT) return extraNext;

    return getScreenAttributes(extraNext).extraNext;
  };

  const getScreenForMoveBack = (id: string) => {
    const { prev } = getScreenAttributes(id);

    if (!prev) return '';

    if (getScreenAttributes(prev).screenType !== QuizStepTypes.SPLIT) return prev;

    return getScreenAttributes(prev).next;
  };

  const updateUserPreferences = (preference: string, preferenceData: unknown) => {
    dispatch(updateUserInformation(preference, preferenceData));
  };

  const updateCurrentStep = (step: number) => {
    dispatch(updateUserInformation('step', step));
  };

  const trackAnalyticUserChoice = (id: string, text: string | string[]) => {
    const { analytic } = getScreenAttributes(id);

    if (!analytic?.clickEvent) return;

    analyticsService.trackEvent(analytic.clickEvent, {
      context: text,
    });
  };

  const trackAnalyticForScreen = (id: string) => {
    const step = getScreenAttributes(id);

    if (!step || !step.analytic || !step.analytic.baseEvent) return;

    analyticsService.trackEvent(step.analytic.baseEvent, step.analytic.baseEventProperties);
  };

  const next = async ({ id, value, force }: NextArgs) => {
    if (id === flow.initialScreen || 'unknown') {
      dispatch(updateUserInformation('initialStep', flow.initialScreen));
    } else if (!userPreferences.initialStep) {
      navigateTo(flow.initialScreen, force);
      return;
    }

    if (value) {
      trackAnalyticUserChoice(id, value);
    }
    const nextScreen = getNextScreen(id);

    navigateTo(nextScreen, force);
  };

  const extraNext = (id: string) => {
    const extraNextScreen = getExtraNextScreen(id);

    if (!extraNextScreen) return;

    navigateTo(extraNextScreen);
  };

  const back = async (id: string, force = true) => {
    const prevScreen = getScreenForMoveBack(id);

    if (!prevScreen) return;

    navigateTo(prevScreen, force);
  };

  const getOffers = () => {
    const attributes = flow.offers.attributes as OffersAttributes[];
    const funnelConditions = getConditionAttributes(store.getState());

    const offersWithCondition = attributes.find((item) => {
      if (item.condition) {
        return [funnelConditions].find(evalCondition(item.condition));
      }
    });

    if (!!offersWithCondition) {
      const { condition: _, ...offersByLocale } = offersWithCondition;

      return offersByLocale;
    }

    const defaultOffers = flow.offers.attributes[flow.offers.attributes.length - 1];

    return defaultOffers;
  };

  const offers = getOffers();

  const completeQuiz = async (email: string, productName: string) => {
    const { overriddenFunnelName } = flow.attributes;

    dispatch(
      createOrder({
        email,
        offerName: productName,
        quizName: overriddenFunnelName || name,
        analyticsIds,
        emailFlow,
        reports: emailFlowReports,
      }),
    );
    dispatch(fetchOffersByCategory(offers));
  };

  const signIn = () => {
    redirectToLogin();
  };

  const { defaultUserProfile } = flow.attributes;

  useEffect(() => {
    if (!defaultUserProfile) {
      return;
    }

    updateDefaultUserPreferences(defaultUserProfile);
  }, [defaultUserProfile, updateDefaultUserPreferences]);

  const contextValue = {
    completeQuiz,
    trackAnalyticForScreen,
    trackAnalyticUserChoice,
    userPreferences,
    updateUserPreferences,
    updateUserSettings,
    getScreenAttributes,
    next,
    extraNext,
    back,
    updateCurrentStep,
    link,
    offers,
    signIn,
    flowId: flow.id,
  };

  return <QuizContext.Provider value={contextValue}>{children}</QuizContext.Provider>;
};

export const useQuizStep = (id: string) => {
  const {
    back,
    trackAnalyticForScreen,
    next,
    extraNext,
    getScreenAttributes,
    updateUserPreferences,
    updateUserSettings,
    userPreferences,
    completeQuiz,
    updateCurrentStep,
    link,
    offers,
    flowId,
    signIn,
  } = useContext(QuizContext);

  const initialStep = getScreenAttributes(id).index;

  const [step, setStep] = useState(initialStep);

  usePostMessageToFunnelBuilderOnRouteChange(id);

  useEffect(() => {
    trackAnalyticForScreen(id);
    updateCurrentStep(initialStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const screenAttributes = useMemo(() => getScreenAttributes(id), [id, flowId]);

  const backStep = useCallback(async () => {
    setStep((prev) => prev - 1);
    await back(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const nextScreen = useCallback(
    async ({ value, force }: { value?: string | string[]; force?: boolean } = {}) => {
      setStep((prev) => prev + 1);
      await next({ id, value, force });
    },
    [next, id],
  );

  const extraNextScreen = useCallback(() => extraNext(id), [extraNext, id]);

  return {
    completeQuiz,
    back: backStep,
    next: nextScreen,
    extraNext: extraNextScreen,
    screenAttributes,
    getScreenAttributes,
    updateUserPreferences,
    updateUserSettings,
    userPreferences,
    step,
    offers,
    link,
    signIn,
  };
};
