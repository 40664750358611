import { PaymentError as PaymentFormError, PaymentErrorCode } from 'core/payments/entities';
import { Case, Switch } from '../SwitchCase';
import PaymentErrorCardExpired from './PaymentErrorCardExpired';
import PaymentErrorDebitCard from './PaymentErrorDebitCard';
import PaymentErrorInsufficientFunds from './PaymentErrorInsufficientFunds';
import PaymentErrorInvalidCVV from './PaymentErrorInvalidCVV';
import PaymentErrorLimitExceeded from './PaymentErrorLimitExceeded';
import PaymentErrorProcessing from './PaymentErrorProcessing';
import PaymentErrorWrongNumber from './PaymentErrorWrongNumber';

export type PaymentErrorProps = {
  onTryAgain: () => void;
  paymentError: PaymentFormError | null;
};

const PaymentError = ({ paymentError, onTryAgain }: PaymentErrorProps) => {
  return (
    <Switch value={paymentError?.code}>
      <Case value={PaymentErrorCode.DEBIT_CARD_NOT_SUPPORTED}>
        <PaymentErrorDebitCard onTryAgain={onTryAgain} />
      </Case>
      <Case value={PaymentErrorCode.INVALID_EXPIRATION_DATE}>
        <PaymentErrorCardExpired onTryAgain={onTryAgain} />
      </Case>
      <Case value={PaymentErrorCode.INVALID_CARD_NUMBER}>
        <PaymentErrorWrongNumber onTryAgain={onTryAgain} />
      </Case>
      <Case value={PaymentErrorCode.INSUFFICIENT_FUNDS}>
        <PaymentErrorInsufficientFunds onTryAgain={onTryAgain} />
      </Case>
      <Case value={PaymentErrorCode.INVALID_CVV2_CODE}>
        <PaymentErrorInvalidCVV onTryAgain={onTryAgain} />
      </Case>
      <Case value={PaymentErrorCode.PAYMENT_AMOUNT_LIMIT_EXCESS}>
        <PaymentErrorLimitExceeded onTryAgain={onTryAgain} />
      </Case>
      <Case default>
        <PaymentErrorProcessing onTryAgain={onTryAgain} />
      </Case>
    </Switch>
  );
};

export default PaymentError;
