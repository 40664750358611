import CircularProgress from 'core/common/components/CircularProgress';
import { LoaderWrapperStyled } from './styled';

const PaymentLoader = () => (
  <LoaderWrapperStyled>
    <CircularProgress />
  </LoaderWrapperStyled>
);

export default PaymentLoader;
