import { HTMLAttributes } from 'react';
import { EmojiStyled } from './styled';

type EmojiProps = Omit<HTMLAttributes<HTMLSpanElement>, 'src'>;

const Emoji = ({ children, ...otherProps }: EmojiProps) => {
  return <EmojiStyled {...otherProps}>{children}</EmojiStyled>;
};

export default Emoji;
