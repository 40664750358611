import { useTranslation } from 'react-i18next';
import paymentFailed from 'core/common/assets/images/payment_failed.svg';
import Button from '../ButtonDeprecated';
import { ImageStyled, TextStyled, TitleStyled, WrapperStyled } from './styled';

export type PaymentGeneralErrorProps = {
  onTryAgain?: () => void;
};

const PaymentGeneralError = ({ onTryAgain = () => {} }: PaymentGeneralErrorProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'paymentFailed',
  });

  return (
    <WrapperStyled>
      <TitleStyled>{t('title')}</TitleStyled>
      <TextStyled>{t('text')}</TextStyled>
      <ImageStyled src={paymentFailed} alt="payment failed" width={90} height={90} />
      <Button onClick={onTryAgain}>{t('button')}</Button>
    </WrapperStyled>
  );
};

export default PaymentGeneralError;
