import PaymentError from 'core/common/components/PaymentError';
import PaymentGeneralError from 'core/common/components/PaymentGeneralError';
import { Languages } from 'core/localization/entities';
import { IfLanguage } from 'core/localization/ui';
import { PaymentError as PaymentFormError } from 'core/payments/entities';
import { Wrapper } from './styled';

export type PaymentFormFailedProps = {
  onTryAgain?: () => void;
  paymentError: PaymentFormError | null;
};

const PaymentFormFailed = ({ onTryAgain = () => {}, paymentError }: PaymentFormFailedProps) => {
  return (
    <>
      <IfLanguage value={Languages.ES}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.FR}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.DE}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.TR}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.JA}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.PL}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.ZH}>
        <PaymentGeneralError onTryAgain={onTryAgain} />
      </IfLanguage>
      <IfLanguage value={Languages.EN}>
        <Wrapper>
          <PaymentError onTryAgain={onTryAgain} paymentError={paymentError} />
        </Wrapper>
      </IfLanguage>
    </>
  );
};

export default PaymentFormFailed;
