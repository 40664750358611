import styled from 'styled-components';

export const Paypal = styled.div`
  max-height: 45px;
  border-radius: 4px;
  background: #ffc439;

  &:hover {
    background: #f3ba37;
  }
`;
