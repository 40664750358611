import { getQueryParams } from 'core/common/utils/getQueryParams';

export function useGetTokenFromQuery() {
  const { token: parsedToken } = getQueryParams<{ token: string }>();

  const token = parsedToken || '';

  return {
    token,
  };
}
