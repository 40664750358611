import { useSelector } from 'core/common/hooks';
import { getFunnelName } from '../store';

export const useFunnelName = () => {
  const funnelName = useSelector(getFunnelName);

  return {
    funnelName,
  };
};
