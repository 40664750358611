import styled from 'styled-components';
import Button from 'core/common/components/ButtonDeprecated';
import PaymentErrorCaption from '../PaymentErrorCaption';
import PaymentErrorSolution from '../PaymentErrorSolution';
import PaymentErrorTitle from '../PaymentErrorTitle';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 10px;
  color: ${(props) => props.theme.colors.typography600};
`;

export const ImageWrapper = styled.div`
  margin-right: auto;
  margin-bottom: 48px;
  margin-left: auto;
`;

export const Title = styled(PaymentErrorTitle)`
  margin-bottom: 24px;
`;

export const Solution = styled(PaymentErrorSolution)`
  margin-bottom: 12px;
`;

export const Caption = styled(PaymentErrorCaption)`
  margin-bottom: 32px;
`;

export const RetryButton = styled(Button)`
  width: 100%;
  max-width: 320px;
  margin-top: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
`;

export const Link = styled.a`
  font-weight: 600;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.info};
`;

export const Tip = styled.p`
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 120%;
`;

export const TipAsterisk = styled.span`
  color: ${(props) => props.theme.colors.warning500};
`;
