import { HTMLAttributes } from 'react';
import Emoji from 'core/common/components/Emoji';
import { useLogger } from 'core/common/hooks';
import { emojiMap } from './emojiMap';
import { EmojiContainer } from './styled';

export type DeprecatedEmojiProps = {
  emoji: string;
  width?: number;
  height?: number;
  desktopLarge?: boolean;
} & HTMLAttributes<HTMLSpanElement>;

const DeprecatedEmoji = ({
  emoji,
  className,
  height = 40,
  width = 40,
  desktopLarge = true,
}: DeprecatedEmojiProps) => {
  const data = emojiMap[emoji];
  const logger = useLogger();

  if (!data) {
    logger.warn(`Data for emoji ${emoji} not found`);
    return <Emoji className={className}>{emoji}</Emoji>;
  }

  return (
    <EmojiContainer
      aria-label={data.src}
      url={data.url}
      desktopLarge={desktopLarge}
      width={width}
      height={height}
      className={className}
    />
  );
};

export default DeprecatedEmoji;
