import { memo } from 'react';
import { PaymentError, PaymentOrderDescription } from 'core/payments/entities';
import Modal from '../../Modal';
import PaymentResign from '../PaymentResign';
import { CloseButtonStyled, WrapperStyled } from './styled';

export type PaymentResignDialogProps = {
  isOpen?: boolean;
  onError?: (data: PaymentError) => void;
  onSucceed?: (data: { order?: PaymentOrderDescription }) => void;
  onClose: () => void;
};

const PaymentResignDialog = ({
  isOpen = false,
  onSucceed,
  onError,
  onClose,
}: PaymentResignDialogProps) => {
  return (
    <Modal open={isOpen}>
      <WrapperStyled>
        <CloseButtonStyled onClick={onClose} />
        <PaymentResign onError={onError} onSuccess={onSucceed} />
      </WrapperStyled>
    </Modal>
  );
};

export default memo(PaymentResignDialog);
