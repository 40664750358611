import Modal from 'core/common/components/Modal';
import { PaymentError } from 'core/payments/entities';
import PaymentFormFailed from '../PaymentFormFailed';
import { CloseButtonStyled, WrapperStyled } from './styled';

export type PaymentDialogFailedProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onTryAgain: () => void;
  paymentError: PaymentError | null;
};

const PaymentDialogFailed = ({
  isOpen = false,
  onClose = () => {},
  onTryAgain,
  paymentError,
}: PaymentDialogFailedProps) => (
  <Modal open={isOpen} onClose={onClose}>
    <WrapperStyled>
      <CloseButtonStyled onClick={onClose} />
      <PaymentFormFailed paymentError={paymentError} onTryAgain={onTryAgain} />
    </WrapperStyled>
  </Modal>
);

export default PaymentDialogFailed;
