import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentError, PaymentOrderDescription } from 'core/payments/entities';
import { useResignPayment } from 'core/payments/hooks';
import PaymentResignForm, { PaymentResignFormProps } from '../PaymentResignForm';

export type PaymentResignProps = {
  onError?: (data: PaymentError) => void;
  onSuccess?: (data: { order?: PaymentOrderDescription }) => void;
  onSubmit?: () => void;
  className?: string;
  formProps?: Pick<PaymentResignFormProps, 'appearance' | 'styles'>;
};

const PaymentResign = ({
  onSuccess: passedOnSuccess,
  onError,
  onSubmit,
  className,
  formProps = {},
}: PaymentResignProps) => {
  const { appearance: passedFormAppearance = {}, styles: passedFormStyles = {} } = formProps;

  const { resignFormData, resignFormSucceed, resignFormFailed } = useResignPayment();

  const { t } = useTranslation('translation', {
    keyPrefix: 'app-subscription-4-7d-trial-per-day.payment',
  });

  const onSuccess = useCallback(
    (data: { order: PaymentOrderDescription }) => {
      resignFormSucceed();

      if (passedOnSuccess) {
        passedOnSuccess(data);
      }
    },
    [passedOnSuccess, resignFormSucceed],
  );

  if (!resignFormData) {
    if (onError) {
      onError({
        message: 'Invalid resign form data',
        details: { resignFormData },
      });
    }

    return null;
  }

  const formStyles: PaymentResignFormProps['styles'] = {
    'resign-submit-button': {
      'border-radius': '30px',
      height: '44px',
      'text-transform': 'none',
      padding: '10px 25px',
      'background-color': '#69B13F',

      '.title-icon': {
        display: 'none',
      },
    },
    ...passedFormStyles,
  };

  const formAppearance: PaymentResignFormProps['appearance'] = {
    submitButtonText: t('form.resign.submitButtonText') || 'Buy',
    googleFontLink: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap',
    ...passedFormAppearance,
  };

  return (
    <PaymentResignForm
      className={className}
      resignRequest={resignFormData}
      onSuccess={onSuccess}
      onSubmit={onSubmit}
      onFail={resignFormFailed}
      onError={resignFormFailed}
      styles={formStyles}
      appearance={formAppearance}
    />
  );
};

export default PaymentResign;
