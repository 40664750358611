import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  antiFraudFirstRebillExperimentName,
  antiFraudPurchaseConfirmationExperimentName,
} from 'core/feature-flags/config';
import { useFeatureFlags } from 'core/feature-flags/hooks';
import {
  CardPaymentEntities,
  PaymentOrderDescription,
  PaymentProcessSteps,
  PaymentType,
} from 'core/payments/entities';
import { countriesWithoutPaypal, Currency, Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { Case, Switch } from '../../SwitchCase';
import PaymentFormFailed from '../PaymentFormFailed';
import PaymentLoader from '../PaymentLoader';
import { PaymentMethods, PaymentMethodsWithoutPaypal } from '../PaymentMethods';
import { usePaymentForm } from './usePaymentForm';
import { TitleStyled, WrapperStyled } from './styled';

export type PaymentFormProps = {
  productId: string;
  onPaymentSuccess?: (data: { order?: PaymentOrderDescription }) => void;
  onPaymentFailed?: () => void;
  children?: ReactNode;
  currency?: Currency;
};

const PaymentForm = ({ children, ...otherProps }: PaymentFormProps) => {
  const {
    paymentError,
    shouldRenderPaypal,
    shouldRenderCardPayment,
    shouldRenderApplePay,
    paymentProcessStep,
    paymentFormPaypalData,
    paymentFormMerchantData,
    paymentFormFailed,
    trackOrderCardStartProcessing,
    trackOrderPaypalStartProcessing,
    paymentSucceed,
    paypalFailed,
    paymentVerify,
    cardFormError,
    paymentClick,
    tryAgain,
    funnelName,
  } = usePaymentForm(otherProps);

  const featureFlags = useFeatureFlags();

  const [paymentMethod, setPaymentMethod] = useState('');

  const onOrderCardStartProcessing = (paymentType: CardPaymentEntities) => {
    trackOrderCardStartProcessing(paymentType);
    setPaymentMethod(paymentType);
  };

  const onOrderPaypalStartProcessing = () => {
    trackOrderPaypalStartProcessing();
    setPaymentMethod(PaymentType.PAYPAL);
  };

  const onSucceed = (data: { order?: PaymentOrderDescription }) => {
    featureFlags.getExperimentGroup(antiFraudPurchaseConfirmationExperimentName);
    featureFlags.getExperimentGroup(antiFraudFirstRebillExperimentName);

    paymentSucceed(data, paymentMethod);
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'app-subscription-4-7d-trial-per-day.payment',
  });

  if (paymentProcessStep === PaymentProcessSteps.FAILED) {
    return <PaymentFormFailed paymentError={paymentError} onTryAgain={tryAgain} />;
  }

  return (
    <WrapperStyled>
      {paymentProcessStep === PaymentProcessSteps.IN_PROCESS && <PaymentLoader />}
      <TitleStyled>{t('title')}</TitleStyled>
      <Location>
        <LocationOption option={countriesWithoutPaypal}>
          <PaymentMethodsWithoutPaypal
            onPaymentClick={paymentClick}
            shouldRenderCardPayment={shouldRenderCardPayment}
            shouldRenderApplePay={shouldRenderApplePay}
            formMerchantData={paymentFormMerchantData}
            onFormFailed={paymentFormFailed}
            onCardFormError={cardFormError}
            onVerify={paymentVerify}
            onOrderCardStartProcessing={onOrderCardStartProcessing}
            onSucceed={onSucceed}
          >
            {children}
          </PaymentMethodsWithoutPaypal>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <Switch value={funnelName}>
            <Case value="attachment-style">
              <PaymentMethods
                onPaymentClick={paymentClick}
                shouldRenderPaypal={shouldRenderPaypal}
                shouldRenderCardPayment={shouldRenderCardPayment}
                shouldRenderApplePay={shouldRenderApplePay}
                formMerchantData={paymentFormMerchantData}
                onFormFailed={paymentFormFailed}
                onCardFormError={cardFormError}
                onVerify={paymentVerify}
                onOrderPaypalStartProcessing={onOrderPaypalStartProcessing}
                onOrderCardStartProcessing={onOrderCardStartProcessing}
                onSucceed={onSucceed}
                paypalPaymentUrl={paymentFormPaypalData}
                onPaypalFailed={paypalFailed}
                defaultPaymentMethod={PaymentType.CARD}
              >
                {children}
              </PaymentMethods>
            </Case>
            <Case value="attachment-style-zodiac">
              <PaymentMethods
                onPaymentClick={paymentClick}
                shouldRenderPaypal={shouldRenderPaypal}
                shouldRenderCardPayment={shouldRenderCardPayment}
                shouldRenderApplePay={shouldRenderApplePay}
                formMerchantData={paymentFormMerchantData}
                onFormFailed={paymentFormFailed}
                onCardFormError={cardFormError}
                onVerify={paymentVerify}
                onOrderPaypalStartProcessing={onOrderPaypalStartProcessing}
                onOrderCardStartProcessing={onOrderCardStartProcessing}
                onSucceed={onSucceed}
                paypalPaymentUrl={paymentFormPaypalData}
                onPaypalFailed={paypalFailed}
                defaultPaymentMethod={PaymentType.CARD}
              >
                {children}
              </PaymentMethods>
            </Case>
            <Case default>
              <PaymentMethods
                onPaymentClick={paymentClick}
                shouldRenderPaypal={shouldRenderPaypal}
                shouldRenderCardPayment={shouldRenderCardPayment}
                shouldRenderApplePay={shouldRenderApplePay}
                formMerchantData={paymentFormMerchantData}
                onFormFailed={paymentFormFailed}
                onCardFormError={cardFormError}
                onVerify={paymentVerify}
                onOrderPaypalStartProcessing={onOrderPaypalStartProcessing}
                onOrderCardStartProcessing={onOrderCardStartProcessing}
                onSucceed={onSucceed}
                paypalPaymentUrl={paymentFormPaypalData}
                onPaypalFailed={paypalFailed}
                defaultPaymentMethod={PaymentType.PAYPAL}
              >
                {children}
              </PaymentMethods>
            </Case>
          </Switch>
        </LocationOption>
      </Location>
    </WrapperStyled>
  );
};

export default PaymentForm;
