import { HTMLAttributes } from 'react';
import { ButtonStyled, IconStyled } from './styled';

type ButtonBackProps = HTMLAttributes<HTMLButtonElement>;

const ButtonBack = (props: ButtonBackProps) => {
  return (
    <ButtonStyled {...props}>
      <IconStyled name="arrow-simple" />
    </ButtonStyled>
  );
};

export default ButtonBack;
