/* eslint-disable max-len */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useStore } from 'react-redux';
import AppLoader, { AppLoaderVariant } from 'core/common/components/AppLoader';
import { SECOND } from 'core/common/constants';
import { LoadingStatuses } from 'core/common/entities';
import { useServices } from 'core/common/hooks';
import { RootInitialState } from 'core/common/store';
import { cloneDeep } from 'core/common/utils/cloneDeep';
import { evalCondition } from 'core/common/utils/evalCondition';
import { removePrimitiveDuplicatesFromArray } from 'core/common/utils/removePrimitiveDuplicatesFromArray';
import { FeatureFlagsConfig } from 'core/feature-flags/entities';
import { useFeatureFlags } from 'core/feature-flags/hooks';
import {
  Flow,
  FunnelAnalyticsType,
  FunnelConfig,
  isBingAds,
  isFacebookPixel,
  isTikTok,
} from 'core/funnel/entities';
import i18n from 'core/i18n';
import { useLanguage } from 'core/localization/hooks';
import { useInitFacebookPixels, useInitBingAds } from 'core/scripts/hooks';
import { useUserLocation } from 'core/user/hooks';
import { getConditionAttributes } from '../store';

type FunnelContextData = {
  flow: Flow;
  analyticsIds: {
    facebook: Array<string>;
    tikTok: Array<string>;
  };
} & Omit<FunnelConfig, 'flows' | 'analytics'>;

export const FunnelContext = createContext<FunnelContextData>(undefined!);

type FunnelProviderProps = {
  children: ReactNode;
  resources: FunnelConfig;
  features: FeatureFlagsConfig['features'];
};

export const FunnelProvider = ({ children, resources, features }: FunnelProviderProps) => {
  const [pathname, setPathname] = useState('');
  const [featuresUpdated, setFeaturesUpdated] = useState(false);
  const [forceShowContent, setForceShowContent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setForceShowContent(true);
    }, 7 * SECOND);

    setPathname(window.location.pathname);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const featureFlags = useFeatureFlags();

  const { analyticsService, loggingService } = useServices();

  const store = useStore<RootInitialState>();

  const { loadingStatus } = useUserLocation();
  const { language } = useLanguage();
  const isLocationLoaded = loadingStatus === LoadingStatuses.FULFILLED;

  const { flows, analytics, ...quizWithoutFlows } = resources;

  const featureValue = featureFlags.getFeatureValue(resources.name, { flow: 'default_flow' });

  const flowId = featureValue.flow;

  const activeFlow: Flow | undefined = flows.find(({ name }) => name === flowId);

  const defaultFlow: Flow = flows[0];

  const getAnalyticsIds = useCallback(
    (
      analyticsGuard: (funnelAnalytics: FunnelAnalyticsType[number]) => boolean,
      funnelAnalytics?: FunnelAnalyticsType,
    ): Array<string> => {
      const funnelConditions = getConditionAttributes(store.getState());

      if (!funnelAnalytics || !Array.isArray(funnelAnalytics)) return [];

      const analyticsObject = funnelAnalytics.find(analyticsGuard);

      if (!analyticsObject) return [];

      const idsWithoutCondition = analyticsObject.attributes
        .filter((attribute) => !attribute.condition)
        .map((attribute) => attribute.id);

      const idsWithCondition = analyticsObject.attributes
        .filter((item) => [funnelConditions].find(evalCondition(item.condition)))
        .map((attribute) => attribute.id);

      return removePrimitiveDuplicatesFromArray([...idsWithoutCondition, ...idsWithCondition]);
    },

    // Ignoring store as a dependency, because conditionAttributes are not dynamic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const facebookPixelIds = useMemo(
    () => getAnalyticsIds(isFacebookPixel, analytics),
    [analytics, getAnalyticsIds],
  );

  const bingAdsIds = useMemo(
    () => getAnalyticsIds(isBingAds, analytics),
    [analytics, getAnalyticsIds],
  );

  const tikTokPixelIds = useMemo(
    () => getAnalyticsIds(isTikTok, analytics),
    [analytics, getAnalyticsIds],
  );

  useInitFacebookPixels(facebookPixelIds);

  useInitBingAds(bingAdsIds);

  useLayoutEffect(() => {
    analyticsService.funnelOpened(resources.name);
  }, [analyticsService, resources.name]);

  useEffect(() => {
    analyticsService.trackUserLanguage(language);
  }, [analyticsService, language]);

  useEffect(() => {
    if (!activeFlow && defaultFlow) {
      loggingService.warn(
        `Flow is missing. "${defaultFlow.name}" was used instead. Be sure to declare the neccessary flow in "quizResources" of the "${resources.name}" funnel.`,
      );
      analyticsService.flowOpened(defaultFlow.name);
    }

    if (activeFlow) {
      analyticsService.flowOpened(activeFlow.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFlow]);

  if (!activeFlow && defaultFlow.translations) {
    i18n.addResourceBundle(
      defaultFlow.language,
      'translation',
      // in cause when defaultFlow.translations object stored in redux
      // all objects have been frozen by immer https://immerjs.github.io/immer/freezing/
      // addResourceBundle method can't work properly with frozen object
      cloneDeep(defaultFlow.translations),
      true,
      true,
    );
  }

  /**
   * @deprecated Use funnel instead of this
   */
  if (!featureFlags.getAttributes().quizName) {
    featureFlags.setAttributes({ quizName: resources.name });
  }

  if (!featureFlags.getAttributes().funnel) {
    featureFlags.setAttributes({ funnel: resources.name });
  }

  if (features && !featuresUpdated) {
    // TODO: implement logic to avoid using of "setFeatures" method
    featureFlags.setFeatures(features);
    setFeaturesUpdated(true);

    return null;
  }

  const isDraftLink = pathname.startsWith('/draft/');
  const showingAppLoader =
    !isDraftLink && !forceShowContent && (!featuresUpdated || !isLocationLoaded);
  const appLoaderVariant: AppLoaderVariant = isLocationLoaded ? 'blurred' : 'filled';

  if (!activeFlow || !features) {
    return (
      <FunnelContext.Provider
        value={{
          ...quizWithoutFlows,
          flow: defaultFlow,
          analyticsIds: { facebook: facebookPixelIds, tikTok: tikTokPixelIds },
        }}
      >
        {showingAppLoader && <AppLoader variant={appLoaderVariant} />}
        {children}
      </FunnelContext.Provider>
    );
  }

  if (activeFlow && activeFlow.translations) {
    i18n.addResourceBundle(
      activeFlow.language,
      'translation',
      // in cause when activeFlow.translations object stored in redux
      // all objects have been frozen by immer https://immerjs.github.io/immer/freezing/
      // addResourceBundle method can't work properly with frozen object
      cloneDeep(activeFlow.translations),
      true,
      true,
    );
  }

  return (
    <FunnelContext.Provider
      value={{
        ...quizWithoutFlows,
        flow: activeFlow,
        analyticsIds: { facebook: facebookPixelIds, tikTok: tikTokPixelIds },
      }}
    >
      {showingAppLoader && <AppLoader variant={appLoaderVariant} />}
      {children}
    </FunnelContext.Provider>
  );
};

export const useFunnelData = () => {
  const context = useContext(FunnelContext);

  if (!context) {
    throw new Error('useFunnelData must be used within a FunnelProvider');
  }

  return context;
};
